<template>
  <div>
    <el-input style="width:300px" placeholder="请输入登录名或角色" v-model="username" clearable>
        <el-button slot="append" icon="el-icon-search"  @click="freshData"></el-button>
    </el-input>
    <el-button type='primary' style='margin-left:20px' @click='addDialogFlag=true' >新增后台用户</el-button>
    <!-- 表格数据 -->
    <el-table :data="tableData" stripe style="width:100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="name" label="登录名"></el-table-column>
      <el-table-column prop="group" label="角色组"></el-table-column>
      <el-table-column prop="add_time" label="创建时间" ></el-table-column>
      <el-table-column prop="login_time" label="登录时间" ></el-table-column>
      <el-table-column fixed="right" width="300" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="readyToEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="deleteID(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <!-- 新增用户 -->
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" @freshData='freshData'/>

    <!-- 编辑用户 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>
  </div>
</template>

<script>
import {web_user_list ,admin_delete} from "@/request/api.js";
import Add from "./component/add.vue";
import Edit from "./component/edit.vue";
export default {
  components: {
    Edit,
    Add,
    // Meal
  },
  created(){  
    this.freshData()
  },
  data() {
    return {
      tableData: [
      ],
      editDialogData: {}, //用户编辑
      editDialogFlag: false,
      addDialogFlag: false,
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
      username:''
    };
  },
  methods: {
    //查询
    freshData() {
      web_user_list().then(
        (res)=>{
          if(res.status==0){
            this.tableData=res.detail
            this.$message.success('获取成功')
          }else{
            this.tableData=[]
          }
        }
      )
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    // 编辑
    readyToEdit(row) {
      console.log(row);
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 删除
    deleteID(id) {
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: action => {
            if(action=='confirm'){
              admin_delete({id}).then(
                (res)=>{
                  if(res.status==0){
                    this.$message.success(res.detail)
                    this.freshData()
                  }
                }
              )
            }
        }
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 30px;
}
.el-row {
  margin-bottom: 20px;
}
.el-checkbox-group {
  display: inline;
}
.el-form-item {
  margin: 0;
}
>>> .el-table th {
  user-select: text;
}
</style>
