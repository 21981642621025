<template>
  <div class="content" :class="isNavCollapse ? 'is-nav-collapse' : ''">
    <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >{{ catalog }}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="subcatalog">{{
        subcatalog
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ target }}</el-breadcrumb-item>
    </el-breadcrumb>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      catalog: "",
      subcatalog: "",
      target: "",
    };
  },
  watch: {
    $route(to, from) {
      let { catalog, subcatalog, target } = to.meta;
      this.catalog = catalog;
      this.subcatalog = subcatalog;
      this.target = target;
    },
  },
  computed: {
    ...mapState({
      isNavCollapse: (state) => state.navCollapse.isNavCollapse,
    }),
  },
  created() {
    let { catalog, subcatalog, target } = this.$route.meta;
    this.catalog = catalog;
    this.subcatalog = subcatalog;
    this.target = target;
  },
};
</script>
 
<style lang = "less" scoped>
.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  box-sizing: border-box;
  top: 70px;
  left: 250px;
  bottom: 0;
  right: 0;
  transition: left 0.3s ease-in-out;
  background-color: #fff;
  padding: 15px 15px 8px;
  overflow-y: auto;
  transition: all 0.8s;
  &.is-nav-collapse {
    left: 65px;
    transition: all 0.8s;
  }
}

/* .content.is-nav-collapse {
  left: 65px;
} */
</style>