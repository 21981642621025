<template>
  <div>
    <el-dialog title="选择禁用的平台" :visible.sync="dialogVisible" :before-close="cancel">
      <el-form :model="form" ref="form" >
        <el-form-item prop="ban_list">
          <el-checkbox-group v-model='form.ban_list'>
              <el-checkbox label="weixin"></el-checkbox>
              <el-checkbox label="qq"></el-checkbox>
              <el-checkbox label="weibo"></el-checkbox>
              <el-checkbox label="gamble"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>            
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    created(){      
        this.form={...this.dialogData}
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{
                ban_list:[]
            },
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            delete this.form._id
            this.$emit('selectChange',this.form)
            this.$emit('update:dialogVisible',false)
        },
    }
}
</script>

<style lang = "less" scoped>
.el-input {
    width: 400px;
}
</style>