<template>
  <div>
    <el-form :model="form" ref="form" label-position="left" label-width="120px">
      <el-form-item label="筛选">
        平台
        <el-select v-model="form.dev" placeholder="请选择" clearable style="margin:0 10px">
          <el-option label="PC端" value="pc_client"></el-option>
          <el-option label="android端" value="android"></el-option>
        </el-select>公告类型
        <el-select v-model="form.type" placeholder="请选择" clearable style="margin:0 10px">
          <el-option label="图片" value="img"></el-option>
          <el-option label="文字" value="text"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button type='primary' @click='freshData'>查询</el-button>

    <el-button @click="addDialogFlag=true" type="primary">新增公告</el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="title" label="公告标题"></el-table-column>
      <el-table-column prop="content" label="公告内容"></el-table-column>
      <el-table-column prop="dev" label="平台"></el-table-column>
      <el-table-column prop="type" label="公告类型"></el-table-column>
      <el-table-column prop="button_text" label="按钮文本"></el-table-column>
      <el-table-column prop="img_src" label="公告图片"></el-table-column>
      <el-table-column prop="jump_way" label="跳转方式" :formatter="formatterWay"></el-table-column>
      <el-table-column prop="jump_link" label="跳转链接"></el-table-column>
      <el-table-column prop="hize" label="弹窗方式"></el-table-column>
      <el-table-column label="当前状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.switch" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" @change='statusChange(scope.row)'></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)">编辑</el-button>
          <el-button @click="deleteID(scope.row._id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>
    <!-- 新增 -->
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" @freshData='freshData'/>
  </div>
</template>

<script>
import { announcement_list,announcement_delete,announcement_status} from '@/request/api.js'
import Edit from "./components/Announcement/edit.vue";
import Add from "./components/Announcement/add.vue";
export default {
  components: {
    Edit,
    Add
  },
  created(){
    this.freshData()
  },
  data() {
    return {
      form: {},
      tableData: [],
      editDialogData: {}, //套餐编辑
      editDialogFlag: false,
      addDialogFlag: false,
      total:0,
      limit:10,
      page:1,
    };
  },
  methods: {
    //查询
    freshData() {
      let obj={...this.form}
      announcement_list(obj).then(
        (res)=>{
          if(res.status==0 && res.detail){
            this.tableData=res.detail
            this.total=res.total
            this.$message.success('获取成功')
          }else{
            this.tableData=[]
            this.total=0
          }
        }
      )
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 删除
    deleteID(id) {
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: action => {
            if(action=='confirm'){
              announcement_delete({id}).then(
                (res)=>{
                  if(res.status==0){
                    this.$message.success(res.detail)
                    this.freshData()
                  }
                }
              )
            }
        }
      });
    },
    //格式化跳转方式
    formatterWay(row,column,cell){
      switch(cell){
        case 1:
          cell='浏览器跳转';
          break;        
        case 2:
          cell='跳到程序购买页面';
          break;        
        case 3:
          cell='跳到程序内部优惠券领取界面';
          break;        
        case 4:
          cell='跳到程序内部的实名';
          break;        
        case 5:
          cell='跳到程序内部的拉新';
          break;
          default:;
      }
      return cell
    },

    //公告状态编辑
    statusChange(row){
      announcement_status({"id":row._id,"switch":row.switch}).then(
        (res) => {
          this.tableData=[]
          this.freshData()
          
        }
      );
    }
  }
};
</script>

<style>
</style>