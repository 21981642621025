<template>
  <div>
    渠道号
    <el-input v-model="channel" style="width:300px;margin:0 20px" clearable></el-input>
    <el-button type="primary" @click='add'>确认添加</el-button>

    <!-- 表单数据 -->
    <el-table :data="tableData" border style="width: 100%;margin-top:20px">
      <el-table-column prop="channel" label="渠道号" width="120"></el-table-column>
      <el-table-column prop="pc" label="PC下载地址" width></el-table-column>
      <el-table-column prop="ios" label="ios下载地址" width></el-table-column>
      <el-table-column prop="android" label="安卓下载地址" width></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)">编辑</el-button>
          <el-button @click="deleteID(scope.row.channel)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" />
  </div>
</template>

<script>
import Edit from "./components/DownloadManagement/edit.vue";
import { channel_version_list, channel_version_del, channel_version_add } from "@/request/api.js";
export default {
  components: {
    Edit
  },
  created(){
    this.freshData()
  },
  data() {
    return {
      channel: "",
      tableData: [],
      editDialogData: {}, //套餐编辑
      editDialogFlag: false
    };
  },
  methods: {
    freshData(){
      channel_version_list().then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            this.tableData = res.detail;
            this.total = res.total;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );
    },
    //渠道号添加
    add(){
      if(!this.channel){
        this.$message.error('渠道不能为空')
        return
      }
      channel_version_add({'channel':this.channel}).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success(res.detail);
            this.freshData()
          }
        }
      );
    },
    //套餐编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 删除
    deleteID(id) {
      // console.log(id);
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: action => {
          if(action=='confirm'){
            channel_version_del({channel:id}).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.freshData()
                }
              }
            );
          }
        }
      });
    }
  }
};
</script>

<style>
</style>