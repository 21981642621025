export default{
    namespaced:true,
    state: {
        isNavCollapse:false
    },
    mutations: {
        changeIsNavCollapse(state){
            // isNavCollapse取反
            state.isNavCollapse = !state.isNavCollapse
        }
    },
    actions: {
    },
}