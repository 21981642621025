<template>
  <div >   
    <el-input       
    placeholder="请输入用户名"
    v-model="username"
    clearable
    style='width:350px;display:inline-block;margin-bottom:20px'>></el-input><span style='color:red'>登录和使用仅支持UID查询</span>
    <br>
    <el-date-picker
      v-model="selectDate"
      value-format="timestamp"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    ></el-date-picker><span style='color:red'>此日期仅供登录和使用查询</span>

    <div>
      <el-select style='display:inline-block;width:350px;margin-top:20px' clearable v-model="enentType" @change="selectChange">
        <el-option label="注册信息" value="register"></el-option>
        <el-option label="支付信息" value="pay"></el-option>
        <el-option label="登录信息" value="login"></el-option>
        <el-option label="使用信息" value="use"></el-option>
      </el-select>
      <el-button @click='search'>查询</el-button>
      <el-button @click='resetTable'>清空所有表格信息</el-button>
    </div>
  <el-tag style="margin-top:20px" size='small' >注册表格</el-tag>
  <el-table :data="registerData" style="width: 100%">
    <el-table-column type="index" width="50"></el-table-column>
    <el-table-column prop="username" label="用户名"></el-table-column>
    <el-table-column prop="cert_status" label="实名认证状态"></el-table-column>
    <el-table-column prop="cert_mobile" label="实名手机"></el-table-column>
    <el-table-column prop="order_no" label="实名存根"></el-table-column>
    <el-table-column prop="mobile" label="注册手机"></el-table-column>
    <el-table-column prop="regis_time" label="注册时间"></el-table-column>
    <el-table-column prop="regis_ip" label="注册IP"></el-table-column>
    <el-table-column prop="REMOTE_PORT" label="注册端口"></el-table-column>
  </el-table>    
  
  <el-tag style="margin-top:20px"  size='medium'>支付表格</el-tag>
  <el-button @click='pay(2)'>导出支付表格</el-button>
  <el-table :data="payData" style="width: 100%">
    <el-table-column type="index" width="50"></el-table-column>
    <el-table-column prop="user" label="用户名"></el-table-column>
    <el-table-column prop="phone" label="手机号"></el-table-column>
    <el-table-column prop="pay_time" label="支付时间"></el-table-column>
    <el-table-column prop="pay_method" label="支付方式"></el-table-column>
    <el-table-column prop="ip" label="支付IP"></el-table-column>
    <el-table-column prop="orderid" label="订单号"></el-table-column>
    <el-table-column prop="transaction_id" label="支付平台订单号"></el-table-column>
    <el-table-column prop="is_pay" label="支付状态"></el-table-column>
  </el-table>    
  
  <el-tag style="margin-top:20px"  size='medium'>登录表格</el-tag>
  <el-button @click='login(2)'>导出登录表格</el-button>
  <el-table :data="loginData" style="width: 100%">
    <el-table-column type="index" width="50"></el-table-column>
    <el-table-column prop="user" label="用户名"></el-table-column>
    <el-table-column prop="login_time" label="登录时间"></el-table-column>
    <el-table-column prop="mac" label="mac地址"></el-table-column>
    <el-table-column prop="ip" label="登录IP"></el-table-column>
    <el-table-column prop="REMOTE_PORT" label="登录端口"></el-table-column>
    <el-table-column prop="138_address" label="登录地址"></el-table-column>
  </el-table>    
  
  <el-tag style="margin-top:20px"  size='medium'>使用表格</el-tag>
  <el-button @click='use(2)'>导出使用表格</el-button>
  <el-table :data="useData" style="width: 100%">
    <el-table-column type="index" width="50"></el-table-column>
    <el-table-column prop="username" label="用户名"></el-table-column>
    <el-table-column prop="mobile" label="手机号"></el-table-column>
    <el-table-column prop="ip" label="源IP"></el-table-column>
    <el-table-column prop="REMOTE_PORT" label="端口"></el-table-column>
    <el-table-column prop="p_ip" label="节点IP"></el-table-column>
    <el-table-column prop="ip_info" label="节点信息"></el-table-column>
    <el-table-column prop="mac" label="mac地址"></el-table-column>
    <el-table-column prop="timestamp" label="使用时间"></el-table-column>
  </el-table>

  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[100, 200, 500, 1000]"
    :page-size="limit"
    :total="total"
    layout="total, sizes, prev, pager, next, jumper"
    >
  </el-pagination>
</div>
</template>

<script>
export default {
  data() {
    return {
      username:"",
      selectDate:[],
      enentType:"",
      registerData:[],//注册表格
      payData:[],//支付表格
      loginData:[],//登录表格
      useData:[],//使用表格
      limit:100,
      currentPage:1,
      total:0
    };
  },
  methods: {
    selectChange(val){
      this.enentType=val
    },
    // 注册信息
    register(){
    },

    // 支付信息
    pay(download){
    },    
    
    // 登录信息
    login(download){
    },    
    
    // 使用信息
    use(download){

    },
    handleSizeChange(val){
      this.limit=val
      this[this.enentType]()
    },    
    handleCurrentChange(val){
      this.currentPage=val
      this[this.enentType]()

    },
    // 查询
    search(){
      this.total=0
      this[this.enentType]()
    },
    // 清空所有表格信息
    resetTable(){
      this.total=0
      this.registerData=[]
      this.loginData=[]
      this.payData=[]
      this.useData=[]
    },
  }
};
</script>

<style scoped>
.table-helper {
  margin-bottom: 20px;
}
>>>.el-table th {
  user-select: text;
}
.el-tag {
  
}
</style>
