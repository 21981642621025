<template>
  <div>
    <el-dialog
      title="添加套餐"
      :visible.sync="dialogVisible"
      :before-close="cancel">
        <el-form :model="form">
            <el-form-item label="同时在线数">
                <el-input v-model="form.online" autocomplete="off" onkeyup='value=value.replace(/\D/g,"")'></el-input>
            </el-form-item>
            <el-form-item label="添加套餐">
                <el-select v-model="form.id" placeholder="请选择" @change="selectChange">
                    <el-option
                    v-for="item in options"
                    :key="item._id"
                    :label="item.type+'-'+item.name"
                    :value="item._id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { product_list,user_add_meal } from "@/request/api.js";

export default {
    created(){
        product_list({'is_published':1}).then(
            (res)=>{
                if(res.status==0){
                    this.options=res.detail
                }
            }
        )
    },
    props:{
        dialogVisible:{
            type:Boolean,
            default:false
        },
        username:{
            type:String,
            default:"",
        }
    },
    data(){
        return {
            form:{},
            options:[]
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            if(!this.form.id){
                this.$message.error('请先选择套餐')
                return
            }
            if(!this.form.online || this.form.online=='0'){
                this.$message.error('在线数必须为正整数')
                return
            }
            this.form.online.replace(/\D/,'')
            this.form.online=/\D/.test(this.form.online)?'1':String(this.form.online)
            user_add_meal({
                online:this.form.online ? (Number(this.form.online)?Number(this.form.online):1) : 1,
                id:this.form.id,
                username:this.username ? this.username : ''
            }).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success(res.detail)
                        this.$emit('update:dialogVisible',false)
                    }
                }
            )
            
        },
        //套餐选择
        selectChange(val){
            console.log(val);
            this.form.id=val
        }
    }
}
</script>

<style>

</style>