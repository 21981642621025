import { render, staticRenderFns } from "./PayStatistical.vue?vue&type=template&id=51f64fae&scoped=true&"
import script from "./PayStatistical.vue?vue&type=script&lang=js&"
export * from "./PayStatistical.vue?vue&type=script&lang=js&"
import style0 from "./PayStatistical.vue?vue&type=style&index=0&id=51f64fae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f64fae",
  null
  
)

export default component.exports