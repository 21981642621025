<template>
  <div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" :before-close="cancel">
      <el-form :model="form" ref="form" label-position="left" label-width="120px">
        <el-form-item label="云主机名" prop="cloud_server">
          <el-input style="width:350px"  v-model="form.cloud_server" clearable></el-input>
        </el-form-item>        
        <el-form-item label="供应商" prop="suply">
          <el-input style="width:350px" v-model="form.suply" clearable></el-input>
        </el-form-item>        
        <el-form-item label="地区" prop="city">
          <el-input style="width:350px" v-model="form.city" clearable></el-input>
        </el-form-item>        
        <el-form-item label="手机号" prop="mobile">
          <el-input style="width:350px" v-model="form.mobile" clearable></el-input>
        </el-form-item>          
        <el-form-item label="指定用户" prop="user">
          <el-input style="width:350px" v-model="form.user" clearable></el-input>
        </el-form-item>       
        <el-form-item label="月结日期" prop="flow_date">
          <el-input style="width:350px" v-model="form.flow_date" clearable></el-input>
        </el-form-item> 
        <el-form-item label="节点类型" prop="vip">
          <el-select v-model="form.vip" clearable>
            <el-option label="共享" :value="1"></el-option>
            <el-option label="专线" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { static_vps_edit } from "@/request/api.js";
export default {
    created(){
        this.form={...this.dialogData}
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{},
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit(){
          let obj = {}
          obj.id=this.form._id;
          if(this.form.cloud_server){
            obj.cloud_server=this.form.cloud_server
          }
          if(this.form.suply){
            obj.suply=this.form.suply
          }          
          if(this.form.city){
            obj.city=this.form.city
          }             
          if(this.form.mobile){
            obj.mobile=this.form.mobile
          }          
          if(this.form.flow_date){
            obj.flow_date=Number(this.form.flow_date)
          }   
          if(this.form.user){
            obj.user=this.form.user
          }          
          if(this.form.vip){
            obj.vip=this.form.vip
          }
          static_vps_edit(obj).then(
            (res) => {
              if (res.status == 0) {
                this.$message.success(res.detail);
                this.$emit('freshData')
                this.$emit('update:dialogVisible',false)

              }
            }
          );
        }
    }
}
</script>

<style lang = "less" scoped>
.el-input {
    width: 400px;
}
</style>