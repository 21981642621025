import Vue from 'vue'
import Vuex from 'vuex'
import navCollapse from "./navCollapse/index"
import userPath from "./userPath/index"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    navCollapse,
    userPath
  }
})
