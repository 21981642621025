<template>
  <div>
    <el-dialog title="新增banner图" :visible.sync="dialogVisible" :before-close="cancel">
      <el-form :model="form" label-width="200px" label-position="left">
        <el-form-item label="平台">
          <el-radio-group v-model="form.dev">
            <el-radio label="pc_client">pc客户端</el-radio>
            <el-radio label="android">android客户端</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="banner图位置" v-if='form.dev=="android"'>
          <el-select v-model="form.index" placeholder="请选择" clearable style="margin:0 10px">
            <el-option label="个人中心轮播banner图" :value="0"></el-option>
            <el-option label="首页轮播banner图" :value="1"></el-option>
          </el-select>
        </el-form-item>     
        <el-form-item label="跳转方式">
          <el-select v-model="form.jump_way" placeholder="请选择" clearable>
            <el-option label="浏览器跳转" :value="1"></el-option>
            <el-option label="跳到程序购买页面" :value="2"></el-option>
            <el-option label="跳到程序内部优惠券领取界面" :value="3"></el-option>
            <el-option label="跳到程序内部的实名" :value="4"></el-option>
            <el-option label="跳到程序内部的拉新" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转链接">
          <el-input v-model="form.jump_link"></el-input>
        </el-form-item>
        <el-form-item label="banner图片">
          <el-input v-model="form.img_src"></el-input>
        </el-form-item>               
        <el-form-item label="是否显示">
          <el-radio-group v-model="form.switch">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>  
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { banner_add } from "@/request/api.js";

export default {
  created() {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    cancel() {
      this.$emit("update:dialogVisible", false);
    },
    submit() {
      let obj={...this.form}
      banner_add(obj).then(
        (res)=>{
          if(res.status==0){
            this.$message.success(res.detail)
            this.$emit("freshData");
            this.$emit("update:dialogVisible", false);
          }
        }
      )
    }
  }
};
</script>

<style lang = "less" scoped>
.el-input {
  width: 400px;
}
</style>