<template>
  <div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      :before-close="cancel"
      append-to-body>
        <el-form :model="form">
            <el-form-item label="同时在线数">
                <el-input v-model="form.online" autocomplete="off"></el-input>
            </el-form-item>            
            <el-form-item label="套餐总流量">
                <el-input v-model="form.flow_total" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {user_edit_meal} from '@/request/api.js'
export default {
    created(){
        this.form={...this.dialogData}
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            tableData:[
                {'username':1,'mobile':1,'dev':1,'cert_stattus':1,'cert_mobile':1,'order_no':1,'enterprise_cert_status':1,'ip':1,'REMOTE_PORT':1,'regis_time':1,'total':1},
                {'username':2,'mobile':2,'dev':2,'cert_stattus':2,'cert_mobile':2,'order_no':2,'enterprise_cert_status':2,'ip':2,'REMOTE_PORT':2,'regis_time':2,'total':2}
            ],
            form:{}
        }
    },
    methods:{
        cancel(){
             this.$emit('update:dialogVisible',false)
        },
        submit(){
            let obj={
                id:this.form.id,
                flow_total:this.form.flow_total?parseInt(this.form.flow_total):0,
                online:this.form.online?parseInt(this.form.online):1
            }
            user_edit_meal(obj).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success(res.detail)
                        this.$emit('freshData')
                        this.$emit('update:dialogVisible',false)
                    }else{
                        this.$message.error(res.detail)
                    }
                }
            )
        },
        readyToEdit(row){
            console.log(row);            
        }
    }
}
</script>

<style>

</style>