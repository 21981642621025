<template>
    <div class="header">
        <div class="header-title">4G管理系统</div>
        <div class="collapse-btn" @click="navToggle">
        <i class="el-icon-menu"></i>
        </div>
        <section class="header-right">
            <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">admin
                <i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </section>
  </div>
</template>

<script>
import {mapMutations,mapState} from "vuex"
export default {
    data () {
        return {
        }
    },
    computed:{
      ...mapState({
        isNavCollapse:(state) => state.navCollapse.isNavCollapse
      })
    },
    methods:{
      ...mapMutations({
        changeIsNavCollapse:"navCollapse/changeIsNavCollapse"
      }),
      navToggle(){
        // 修改Vuex里面的isNavCollapse，但是不能直接在这里修改，而是调用 changeIsNavCollapse方法。
        this.changeIsNavCollapse();
      },
      handleCommand(val){
        if(val=='logout'){
          console.log('退出登录');
          this.$cookies.set('user_id','',-1)
          this.$router.push('/login')
          this.$message.success('退出成功')
        }
      },
    },
}
</script>
 
<style lang = "less" scoped>
.header {
  width: 100%;
  height: 70px;
  color: #fff;
  background-color: #409eff;

  .header-title {
    float: left;
    padding: 0 15px;
    line-height: 70px;
    font-size: 22px;
  }

  .collapse-btn {
    float: left;
    line-height: 70px;
    font-size: 20px;
  }

  .header-right {
    float: right;
    display: flex;
    align-items: center;
    height: 70px;
    .user-name {
      padding-right: 15px;
      cursor: pointer;

      .el-dropdown-link {
        color: #fff;
      }
    }
  }
}
.el-icon-menu{
  cursor: pointer;
}
</style>