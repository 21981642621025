<template>
  <div>
    <el-tag style="margin-top:20px" size="small">实名认证统计</el-tag>
    <el-table :data="presonalData" style="width: 100%;margin-bottom:20px">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="total" label="实名总数"></el-table-column>
      <el-table-column prop="old" label="老用户实名数"></el-table-column>
      <el-table-column prop="new" label="新用户实名数"></el-table-column>
    </el-table>

    <el-tag style="margin-top:20px" size="small">企业实名统计</el-tag>
    <el-table :data="enterpriseData" style="width: 100%;margin-bottom:50px">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="total" label="实名总数"></el-table-column>
      <el-table-column prop="old" label="老用户实名数"></el-table-column>
      <el-table-column prop="new" label="新用户实名数"></el-table-column>
    </el-table>

    实名日期
    <el-date-picker
      v-model="cert_time"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    ></el-date-picker>
    <el-button type='primary' @click="freshData" style='margin:20px'>查询</el-button>
    <el-table :data="tableData" style="width: 100%;margin-bottom:50px">
      <el-table-column type="index" label='序号'></el-table-column>
      <el-table-column prop="date" label="日期"></el-table-column>
      <el-table-column prop="total_count" label="当日实名数"></el-table-column>
      <el-table-column prop="suc_percent" label="成功比例"></el-table-column>
      <el-table-column prop="regis_percent" label="注册实名比"></el-table-column>
      <el-table-column prop="cert_success" label="实名成功人数"></el-table-column>
      <el-table-column prop="cert_new_user" label="新用户实名数"></el-table-column>
      <el-table-column prop="cert_old_user" label="老用户实名数"></el-table-column>
      <el-table-column prop="ty_suc" label="天眼成功数"></el-table-column>
      <el-table-column prop="sjb_suc" label="数据宝成功数"></el-table-column>
      <el-table-column prop="wx_suc" label="微信成功数"></el-table-column>
      <el-table-column prop="zfb_suc" label="支付宝成功数"></el-table-column>
      <el-table-column prop="ty_fail" label="天眼失败数"></el-table-column>
      <el-table-column prop="sjb_fail" label="数据宝失败数"></el-table-column>
      <el-table-column prop="wx_fail" label="微信失败数"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { cert_count,cert_day_count} from "@/request/api.js";
export default {
  created(){
    cert_count({}).then(
      (res)=>{
        if(res.status==0){
          this.presonalData=[res.detail.cert]
          this.enterpriseData=[res.detail.enterprise]
        }else{
          this.presonalData=[]
          this.enterpriseData=[]
        }
      }
    ),
    this.freshData()
  },
  data() {
    return {
      channel: "",
      presonalData: [],
      enterpriseData: [],
      cert_time: [],
      tableData:[]
    };
  },
  methods: {
    freshData(){
      let obj = {} 
      if(this.cert_time[0]){
        obj.start_time=this.cert_time[0]
        obj.end_time=this.cert_time[1]
      }
      cert_day_count(obj).then(
        (res)=>{
          if(res.status==0){
            this.$message.success('获取成功')
            this.tableData=res.detail
          }else{
            this.tableData=[]
          }
        }
      )
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    }
  }
};
</script>

<style>
</style>