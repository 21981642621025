<template>
  <div>
    <el-form :model="form" ref="form" label-position="left" label-width="100px">
      <el-form-item label="筛选">
        平台
        <el-select v-model="form.dev" placeholder="请选择" clearable style="margin:0 10px" @change="freshData">
          <el-option label="PC端" value="pc_client"></el-option>
          <el-option label="android端" value="android"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-button @click="addDialogFlag=true" type="primary">新增banner图</el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="index" label="banner位置" :formatter="formatterIndex"></el-table-column>
      <el-table-column prop="img_src" label="banner图片"></el-table-column>
      <el-table-column prop="jump_way" label="跳转方式" :formatter="formatterWay"></el-table-column>
      <el-table-column prop="jump_link" label="跳转链接"></el-table-column>
      <el-table-column prop="switch" label="当前状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.switch" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value='0' @change='switchChange(scope.row)'></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)">编辑</el-button>
          <el-button @click="deleteID(scope.row._id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>
    <!-- 新增 -->
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" @freshData='freshData'/>
  </div>
</template>

<script>
import { banner_list , banner_delete ,banner_edit  } from "@/request/api.js";
import Edit from "./components/BannerManagenment/edit.vue";
import Add from "./components/BannerManagenment/add.vue";
export default {
  components: {
    Edit,
    Add
  },
  created(){

    this.freshData()
  },
  data() {
    return {
      form: {
        dev:'pc_client'
      },
      tableData: [],
      editDialogData: {}, //套餐编辑
      editDialogFlag: false,
      addDialogFlag: false,
    };
  },
  methods: {
    freshData() {
      let obj ={}
      if(this.form.dev){
        obj.dev=this.form.dev
      }
      banner_list(obj).then(res => {
        if (res.status == 0) {
          this.$message.success("获取成功");
          this.tableData = res.detail;
          this.total = res.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    //编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 删除
    deleteID(id) {
      // console.log(id);
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: action => {
            if(action=='confirm'){
              console.log(id);
              banner_delete({id}).then(
                (res)=>{
                  if(res.status==0){
                    this.$message.success(res.detail)
                    this.freshData()
                  }
                }
              )
            }
        }
      });
    },
    //格式化跳转方式
    formatterWay(row,column,cell){
      switch(cell){
        case 1:
          cell='浏览器跳转';
          break;        
        case 2:
          cell='跳到程序购买页面';
          break;        
        case 3:
          cell='跳到程序内部优惠券领取界面';
          break;        
        case 4:
          cell='跳到程序内部的实名';
          break;        
        case 5:
          cell='跳到程序内部的拉新';
          break;
          default:;
      }
      return cell
    },

    //格式化banner位置
    formatterIndex(row,column,cell){
      if(row.dev=='pc_client'){
        cell='左下角'
      }else{
        if(row.index==1){
          cell='首页轮播banner图'
        }else{
          cell='个人中心轮播banner图'
        }
      }
      return cell
    },

    //状态修改
    switchChange(row){
      let obj={...row}
      obj.id=obj._id
      delete obj._id
      banner_edit(obj).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success(res.detail);
            this.freshData()
          }
        }
      );
    }
  }
};
</script>

<style>
</style>