<template>
  <div>
    <el-form :model="form" ref="form" label-position="left" label-width="120px">
      <el-form-item label="筛选">
        状态
        <el-select v-model="form.invoice_status" placeholder="请选择" clearable style="margin:0 10px">
          <el-option label="全部" value=""></el-option>
          <el-option label="申请中" :value="1"></el-option>
          <el-option label="已申请" :value="2"></el-option>
          <el-option label="信息错误" :value="3"></el-option>
          <el-option label="已取消" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户查询" prop="username">
        <el-input style="width:350px" placeholder="请输入用户名或手机号" v-model="form.username" clearable></el-input>
      </el-form-item>
      <el-form-item label="发票申请时间" prop="register_time">
        <el-date-picker
          v-model="form.time"
          format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <el-button type="primary" @click="freshData" style='margin-left:20px'>查询</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" style="width: 100%" >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="date" label="申请时间"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="mobile" label="手机号"></el-table-column>
      <el-table-column label="发票信息" align='left'>
        <template slot-scope="scope">
          <p>发票类型：{{scope.row.invoice_type}}</p>
          <p>金额(元): {{scope.row.invoice_money}}</p>
          <p>发票抬头: {{scope.row.invoice_rise}}</p>
          <p v-if='scope.row.individual_num!=""'>纳税人识别号:{{scope.row.individual_num}}</p>
        </template>
      </el-table-column>
      <el-table-column label="收件信息" align='left'>
        <template slot-scope="scope">
          <p>邮箱地址：{{scope.row.mail_address}}</p>
          <p>联系人: {{scope.row.contacts_name}}</p>
          <p>联系电话: {{scope.row.contact_tel}}</p>
        </template>
      </el-table-column >
      <el-table-column prop="invoice_status_change" label="开票状态" align='center' width="80" :formatter="formatter"></el-table-column>
      <el-table-column label="操作" width="100" align='center'>
        <template slot-scope="scope" >
          <el-button @click="edit(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>

  </div>
</template>

<script>
import { invoice_list} from "@/request/api.js";
import Edit from "./components/InvoiceManagement/edit.vue";
export default {
  components: {
    Edit
  },
  created(){
    this.freshData()
  },
  data() {
    return {
      form: {},
      tableData: [],
      editDialogData: {}, //套餐编辑
      editDialogFlag: false,
      addDialogFlag: false,
      total:0,
      limit:10,
      page:1,
    };
  },
  methods: {
    freshData(){
      let obj={...this.form}
      if(obj.time){
        obj.invoice_time_begin=this.formmaterDate(obj['time'][0])
        obj.invoice_time_end=this.formmaterDate(obj['time'][1])
      }
      obj.limit=this.limit
      obj.page=this.page

      invoice_list(obj).then(
        (res)=>{
          if(res.status==0){
            this.$message.success('获取成功')
            this.tableData=res.detail
            this.total=res.total
          }else{
            this.tableData=[]
            this.total=0
          }
        }
      )
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    formatter(row){
      switch(row.invoice_status){
        case 1:
           row.invoice_status_change='申请中'
           break;        
        case 2:
           row.invoice_status_change='已申请'
           break;        
        case 3:
           row.invoice_status_change='信息错误'
           break;          
        case 4:
           row.invoice_status_change='已取消'
           break;        
        default:;
      }
      return row.invoice_status_change
    }
  }
};
</script>

<style>
</style>