<template>
  <div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      :before-close="cancel">
        <el-form :model="form">
            <el-form-item label="用户名">
                <el-input v-model="form.name" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
                <el-input v-model="form.telephone" autocomplete="off"  onkeyup='value=value.replace(/\D/g,"")'></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { admin_edit } from "@/request/api.js";
export default {
    created(){
        this.form={...this.dialogData}
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{}
        }
    },
    methods:{
        cancel(){
            this.form={}
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            if(!this.form.name || !this.form.pass || !this.form.telephone ){
                this.$message.error('参数不能为空')
                return
            }
            let obj={
                id:this.form.id,
                name:this.form.name,
                pass:this.form.pass,
                telephone:this.form.telephone,
            }
            admin_edit(obj).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success(res.detail)
                        this.$emit('freshData')
                        this.$emit('update:dialogVisible',false)
                    }
                }
            )
        }
    }
}
</script>

<style>

</style>W