<template>
  <div>
    <el-date-picker
      style="margin-top:20px"
      v-model="dateArr"
      format="yyyy-MM-dd"
      type="daterange"
      value-format="yyyy-MM-dd"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="freshData"
    ></el-date-picker>
    <span>此日期仅供表格导出</span>
    <el-button type="primary" icon="el-icon-search" @click="exportData">导出列表</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="orderid" label="订单号"></el-table-column>
      <el-table-column prop="user" label="用户名"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column prop="type" label="所选套餐"></el-table-column>
      <el-table-column prop="many" label="同时在线数"></el-table-column>
      <el-table-column prop="money" label="订单金额"></el-table-column>
      <el-table-column prop="place_order" label="生成订单时间" :formatter="formatterTime2"></el-table-column>
      <el-table-column prop="desc" label="付款方式"></el-table-column>
      <el-table-column prop="ip" label="下单IP"></el-table-column>
      <el-table-column prop="transaction_id" label="支付平台订单号"></el-table-column>
      <el-table-column prop="pay_time" label="付款时间" :formatter="formatterTime1"></el-table-column>
      <el-table-column prop="renew" label="购买类型" :formatter="formatterRenew"></el-table-column>
      <el-table-column prop="money" label="退款金额"></el-table-column>
    </el-table>

    <!--分页器 -->
    <!-- <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page_current"
      :page-sizes="[20, 50, 300, 500]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination> -->
  </div>
</template>

<script>
import {order_show} from '@/request/api.js'
export default {
  created(){
    this.freshData()
  },
  data() {
    return {
      dateArr:'',
      tableData: [],
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
    };
  },
  methods: {
    freshData(){
      let obj={}
      obj.is_pay=3
      if(this.dateArr[0]){
        obj.pay_start_time=this.dateArr[0]
        obj.pay_end_time=this.dateArr[1]
      }
      order_show(obj).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            this.tableData=res.detail
          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    formatterRenew(row,column,cell){
      cell=row.renew==1?'首次购买':'续费购买'
      return cell
    },
    formatterTime1(row,column,cell){
      cell=row.pay_time?this.detailtime((row.pay_time).toString()) : ''
      return cell
    },    
    formatterTime2(row,column,cell){
      cell=row.place_order?this.detailtime((row.place_order).toString()) : ''
      return cell
    },
    //表格导出
    exportData() {
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 20px;
}
>>> .el-table th {
  user-select: text;
}
</style>
