<template>
  <div>
    <el-date-picker
      style="margin-top:20px"
      v-model="dateArr"
      format="yyyy-MM-dd"
      type="daterange"
      value-format="timestamp"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="freshData"
    ></el-date-picker>
    <span>此日期仅供表格导出</span>
    <el-button type="primary" icon="el-icon-search" @click="exportData">导出列表</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="date" label="日期" ></el-table-column>
      <el-table-column prop="pc" label="windosw端下载次数"></el-table-column>
      <el-table-column prop="ios" label="ios端下载次数"></el-table-column>
      <el-table-column prop="android" label="android端下载次数"></el-table-column>
      <el-table-column prop="count" label="总下载次数"></el-table-column>
    </el-table>

    <!--分页器 -->
    <!-- <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[20, 50, 300, 500]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination> -->
  </div>
</template>

<script>
import { down_count} from "@/request/api.js";
export default {
  created(){
    this.freshData()
  },
  data() {
    return {
      dateArr:'',
      tableData: [],
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
    };
  },
  methods: {
    freshData(){
      down_count().then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            this.tableData=res.detail
          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //表格导出
    exportData() {
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 20px;
}
>>> .el-table th {
  user-select: text;
}
</style>
