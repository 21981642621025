import request from "./request"
const pre='https://4ghoutai.zhwanglian.com/api/';

// #region 登录界面===================================================

// 获取手机号
export const get_admin_userinfo = (data) => request.post(pre+'get_admin_userinfo',data)

//获取验证码
export const get_login_code = (data) => request.post(pre+'get_login_code',data)

//登录
export const login = (data) => request.post(pre+'login',data)

// #endregion *********************************************************

//版本统计
export const VersionStatisticsData = (data) => request.post(pre+'VersionStatistics/data',data)



//              #region 服务器与节点 ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 

// 动态服务器列表
export const  dynamic_vps= (data) => request.post(pre+'dynamic_vps',data)

// 动态服务器编辑
export const  dynamic_vps_edit= (data) => request.post(pre+'dynamic_vps_edit',data)

// 动态服务器删除
export const  dynamic_vps_delete= (data) => request.post(pre+'dynamic_vps_delete',data)

// 动态服务器列表
export const  static_vps= (data) => request.post(pre+'static_vps',data)

// 动态服务器编辑
export const  static_vps_edit= (data) => request.post(pre+'static_vps_edit',data)

// 动态服务器删除
export const  static_vps_delete= (data) => request.post(pre+'static_vps_delete',data)

// 运营商列表
export const  operator_list= (data) => request.post(pre+'operator_list',data)

// 运营商禁用
export const  ban_operator= (data) => request.post(pre+'ban_operator',data)

//              #endregion ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 



//              #region 客户端与内嵌网页管理 ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 

//意见反馈
export const feed_back = (data) => request.post(pre+'feed_back',data)

//举报留言
export const report_list = (data) => request.post(pre+'report_list',data)

//              #endregion  ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 


// #region 数据统计与报表 ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 

// 留存统计
export const retain_count = (data) => request.post(pre+'retain_count',data)

// 付费统计
export const every_day_data = (data) => request.post(pre+'every_day_data',data)

// 续费统计
export const renew_past_new = (data) => request.post(pre+'renew_past_new',data)

// 登录统计
export const every_login_data = (data) => request.post(pre+'every_login_data',data)

// 下载统计
export const down_count = (data) => request.post(pre+'down_count',data)

// 注册统计
export const regis_total = (data) => request.post(pre+'regis_total',data)

// 安装统计
export const install_count = (data) => request.post(pre+'install_count',data)


// #endregion  ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 

//              #region   产品管理★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 

// #region  用户管理===================================================

// 编辑
export const user_edit = (data) => request.post(pre+'user_edit',data)

// 用户套餐详情
export const user_buy = (data) => request.post(pre+'user_buy',data)

// 用户套餐编辑
export const user_edit_meal = (data) => request.post(pre+'user_edit_meal',data)

// 用户套餐添加
export const user_add_meal = (data) => request.post(pre+'user_add_meal',data)

// 删除用户
export const user_delete = (data) => request.post(pre+'user_delete',data)

// 新增用户
export const user_add = (data) => request.post(pre+'user_add',data)

// 用户列表
export const  user_all= (data) => request.post(pre+'user_all',data)

// #endregion *********************************************************


// #region 套餐相关 ===================================================

// 套餐列表
export const  product_list= (data) => request.post(pre+'product_list',data)

// 套餐新增
export const  meal_add= (data) => request.post(pre+'meal_add',data)

// 套餐删除
export const  meal_delete= (data) => request.post(pre+'meal_delete',data)

// 套餐编辑
export const  meal_edit= (data) => request.post(pre+'meal_edit',data)

// #endregion *********************************************************

// 版本更新
export const verison = (data) => request.post(pre+'verison',data)

// 版本编辑
export const verison_edit = (data) => request.post(pre+'verison_edit',data)

// 兑换码使用记录
export const exchange_code_list = (data) => request.post(pre+'exchange_code_list',data)

// 兑换码生成记录
export const redeem_list = (data) => request.post(pre+'redeem_list',data)

// 兑换码取消使用
export const redeem_code_edit = (data) => request.post(pre+'redeem_code_edit',data)

// 兑换码使用记录（表格导出）
export const redeem_code = (data) => request.get(pre+'redeem_code',{params:data})

// 兑换码生成记录（表格导出）
export const code_export = (data) => request.get(pre+'code_export',{params:data})


// 兑换码生成
export const redeem_code_add = (data) => request.post(pre+'redeem_code_add',data)

// 发票列表
export const invoice_list = (data) => request.post(pre+'invoice_list',data)

// 发票编辑
export const invoice_edit = (data) => request.post(pre+'invoice_edit',data)

// 个人实名认证详情
export const cert_record = (data) => request.post(pre+'cert_record',data)

// 企业实名认证详情
export const enterprise_idverify = (data) => request.post(pre+'enterprise_idverify',data)

// 企业实名认证审核状态编辑
export const enterprise_edit = (data) => request.post(pre+'enterprise_edit',data)

// 实名认证统计
export const cert_count = (data) => request.post(pre+'cert_count',data)

// 实名认证每日统计
export const cert_day_count = (data) => request.post(pre+'cert_day_count',data)

// banner列表
export const banner_list = (data) => request.post(pre+'banner_list',data)

// banner编辑
export const banner_edit = (data) => request.post(pre+'banner_edit',data)

// banner删除
export const banner_delete = (data) => request.post(pre+'banner_delete',data)

// banner添加
export const banner_add = (data) => request.post(pre+'banner_add',data)

// 优惠券列表
export const discount_coupon_list = (data) => request.post(pre+'discount_coupon_list',data)

// 优惠券添加、编辑
export const discount_coupon = (data) => request.post(pre+'discount_coupon',data)

// 优惠券状态修改
export const coupon_status = (data) => request.post(pre+'coupon_status',data)

// 优惠券删除
export const discount_coupon_del = (data) => request.post(pre+'discount_coupon_del',data)

// 公告列表
export const announcement_list = (data) => request.post(pre+'announcement_list',data)

// 公告新增
export const announcement_add = (data) => request.post(pre+'announcement_add',data)

// 公告编辑
export const announcement_edit = (data) => request.post(pre+'announcement_edit',data)

// 公告状态修改
export const announcement_status = (data) => request.post(pre+'announcement_status',data)

// 公告删除
export const announcement_delete = (data) => request.post(pre+'announcement_delete',data)

// 下载管理
export const channel_version_list = (data) => request.post(pre+'channel_version_list',data)

// 下载编辑
export const channel_version = (data) => request.post(pre+'channel_version',data)

// 下载地址列表
export const channel_version_link = (data) => request.post(pre+'channel_version_link',data)

// 下载添加
export const channel_version_add = (data) => request.post(pre+'channel_version_add',data)

// 下载删除
export const channel_version_del = (data) => request.post(pre+'channel_version_del',data)



// 订单管理
export const order_show = (data) => request.post(pre+'order_show',data)

// 订单管理(表格导出)
export const order_export = (data) => request.get(pre+'order_export',{params:data})

// 退款
export const pay_status = (data) => request.post(pre+'pay_status',data)


//              #endregion   产品管理★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 



//              #region 后台人员管理 ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 

// 后台人员列表
export const  web_user_list= (data) => request.post(pre+'web_user_list',data)

// 后台人员新增
export const  admin_add= (data) => request.post(pre+'admin_add',data)

// 后台人员删除
export const  admin_delete= (data) => request.post(pre+'admin_delete',data)

// 后台人员编辑
export const  admin_edit= (data) => request.post(pre+'admin_edit',data)

//              #endregion ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ 