<template>
  <div>
    <Header />
    <Nav />
    <Content />
  </div>
</template>

<script>
import Header from './layout/Header.vue'
import Nav from './layout/Nav.vue'
import Content from './layout/Content.vue'
export default {
    components:{
        Header,Nav,Content
    }
};
</script>

<style lang="less" scoped>
</style>