<template>
  <div class>
    <div class="table-helper">
      <el-row>
        <el-col>
          <el-menu
            default-active="1"
            class="el-menu-demo"
            mode="horizontal"
            text-color="#000"
            active-text-color="#409eff"
            style="border: solid 1px #e6e6e6; background-color: #ecf8ff;"
            @select="handleSelect"
          >
            <el-menu-item
              :index="index + 1 + '' "
              v-for="(channel,index) in channels"
              :key="channel.id"
              :label="channel.channel"
            >{{channel.name}}</el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>
    <el-table :data="dataOnPage" stripe style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="time" label="日期"></el-table-column>
      <el-table-column prop="channel" label="渠道"></el-table-column>
      <el-table-column prop="qy_pc_count" label="企业版下载次数"></el-table-column>
    </el-table>

    <div class="table-helper">
      <el-row>
        <el-col :span="16">
          <el-pagination
            @size-change="changeSize"
            @current-change="changePage"
            :current-page="page_current"
            :page-sizes="[10,20, 50, 100]"
            :page-size="page_size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page_count"
          ></el-pagination>
          <!-- <span v-if="tableData.length <= page_size" class="pagination-holder"> </span> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import doPost from '../helper/XHR'
// import Cookies from 'js-cookie'
export default {
  name: "DownloadTotal",
  mounted() {
    // doPost(
    //   {action: 'channel', user_id: Cookies.get('user_id')},
    //   (data) => {
    //     if (data.content.length <= 0) {
    //       return
    //     }
    //     this.channels=[]
    //     data.content.forEach((item)=>{
    //       if(item.type=='2'){
    //         this.channels.push(item)
    //       }
    //     });
    //     this.channels.unshift({
    //       channel: '',
    //       id: '',
    //       name: '全部渠道'
    //     })
    //     this.freshData()
    //   }
    // )
  },
  data() {
    return {
      noAuth: false,
      page_size: 10,
      page_current: 1,
      searchInput: "",
      dialogDeleteVisible: false,
      dialogEditVisible: false,
      tableData: [],
      //   tableDataFiltered: [],
      channels: []
    };
  },
  computed: {
    page_count() {
      return this.tableData.length;
    },
    dataOnPage() {
      let data, start, end;
      start = (this.page_current - 1) * this.page_size;
      end =
        start + this.page_size > this.tableData.length
          ? this.tableData.length
          : start + this.page_size;
      data = this.tableData.slice(start, end);
      return data;
    }
  },
  methods: {
    freshData(channel) {
      console.log(channel);
      let channelName = channel || "";
      //   doPost(
      //     {action: 'z_download', channel: channelName, user_id: Cookies.get('user_id')},
      //     (res) => {
      //         if(!res.status &&res.data.length>=1){
      //             console.log(res.data)
      //             let result= res['data'].sort((a, b) => {
      //                 return b.time - a.time
      //             })
      //             this.tableData = result;
      //         }else {
      //             this.tableData=[];
      //             this.$forceUpdate();
      //         }
      //     }
      //   )
    },
    changePage(c) {
      this.page_current = c;
    },
    changeSize(p) {
      this.page_size = p;
      this.page_current = 1;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);

      console.log(this.channels[key - 1].channel);
      this.freshData(this.channels[key - 1].channel);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 20px;
}
</style>
