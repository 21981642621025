<template>
  <div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" :before-close="cancel">
      <el-form :model="form" label-width="120px" label-position="left">
        <el-form-item label="平台">
          <el-radio-group v-model="form.dev">
            <el-radio label="pc_client" >pc端</el-radio>
            <el-radio label="android" >android端</el-radio>
            <el-radio label="ios" >ios端</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="form.type">
            <el-radio label="img">图片</el-radio>
            <el-radio label="text">文字</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转方式">
          <el-select v-model="form.jump_way" placeholder="请选择" clearable>
            <el-option label="浏览器跳转" :value="1"></el-option>
            <el-option label="跳到程序购买页面" :value="2"></el-option>
            <el-option label="跳到程序内部优惠券领取界面" :value="3"></el-option>
            <el-option label="跳到程序内部的实名" :value="4"></el-option>
            <el-option label="跳到程序内部的拉新" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转链接">
            <el-input v-model="form.jump_link"></el-input>  
            <p style='color:red'>*选择浏览器跳转时填写跳转链接</p>
        </el-form-item>
        
        <el-form-item label='弹窗图片' v-if='form.type=="img"'>
          <el-input v-model='form.img_src'></el-input>
        </el-form-item>    

        <div v-if='this.form.type=="text"'>
          <el-form-item label='公告标题'>
            <el-input v-model='form.title'></el-input>
          </el-form-item>          
          <el-form-item label='公告内容'>
            <el-input type='textarea'  v-model='form.content'></el-input>
          </el-form-item>          
          <el-form-item label='按钮文本'>
            <el-input v-model='form.button_text'></el-input>
          </el-form-item>
        </div>

        <el-form-item label='弹窗方式'>
          <el-radio-group v-model="form.hize">
            <el-radio :label="1">每天弹一次</el-radio>
            <el-radio :label="2">每次启动弹</el-radio>
            <el-radio :label="0">不弹</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {announcement_edit} from '@/request/api.js'
export default {
  created(){
      this.form={...this.dialogData}
      console.log(this.form)
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },    
    dialogData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    cancel() {
      this.$emit("update:dialogVisible", false);
    },
    submit() {

        this.form.id=this.form._id
        this.form.switch=this.form.switch?1:0
        delete this.form._id
        announcement_edit(this.form).then(
        (res) => {
            if (res.status == 0) {
            this.$message.success(res.detail);
            this.$emit('freshData')
            this.$emit("update:dialogVisible", false);
            }
        }
        );
    }
  }
};
</script>

<style lang = "less" scoped>
.el-input {
  width: 400px;
}
</style>