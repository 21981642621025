<template>
    <div class="login-page">
        <section class="login-container">
        <h3 class="login-title">管理员登录</h3>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
            <el-form-item  prop="username">
                <el-input type="text" v-model="ruleForm.username" autocomplete="off" placeholder="账号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" v-model="ruleForm.password" autocomplete="off"  placeholder="密码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="ruleForm.code"  @keyup.enter.native="submitLogin" placeholder="请输入验证码"></el-input>
                <el-button class="get-code-botton" @click="getCode">{{code}}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button style="width:100%" type="primary" @click="submitLogin">登 录</el-button>
            </el-form-item>            
        </el-form>
        </section>
    </div>
</template>

<script>
import {get_admin_userinfo,get_login_code,login} from '@/request/api.js'
export default {
    created(){
    },
    data () {
        return {
            ruleForm:{},
            code:'获取验证码',
            timer:null,
            telephone:'',
            rules:{
                username:[
                    {
                        required:true, //必填项
                        message:"账号不能为空！！！", // 提示语
                        trigger:"blur"  // 触发时机
                    },
                    {
                        validator:this.valiName,
                        trigger:"blur"  
                    }
                ],
                password:[
                    {
                        required:true, 
                        message:"密码不能为空！！！",
                        trigger:"blur"
                    },
                    {
                        validator:this.valiPwd,
                        trigger:"blur" 
                    }
                ]
            },
            sendFlag:true
        }
    },
    methods:{
        valiName(rule, value, callback){
            if(value.length<3||value.length>20){
                callback("用户名必须在3到20个字符内");
            }else{
                callback();
            }
        },        
        valiPwd(rule, value, callback){
            if(value.length<9||value.length>26){
                callback("密码必须在9到26个字符内");
            }else{
                callback();
            }
        },
        submitLogin(){
            login({
                username:this.ruleForm.username,
                password:this.ruleForm.password,
                code:this.ruleForm.code,
                telephone:this.telephone,
            }).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success(res.detail)
                        this.$cookies.set("user_id",res.id,{expires:7})
                        this.$router.push('/User')
                    }
                }
            )
        },
        getCode(){
            if(!this.ruleForm.username){
                this.$message.error('账号不能为空')
                return
            }
            if(!this.sendFlag){
                return
            }
            let num = 60
            this.timer = setInterval(() => {
                if(!num) {
                    clearInterval(this.timer)
                    this.timer = null
                    this.sendFlag=true
                    this.code = '获取验证码'
                }else{
                    num--
                    this.code = `剩余${num}秒`
                }
            },1000)
            get_admin_userinfo({
                name:this.ruleForm.username
            }).then(
                (res)=>{
                    if(res.status==0){
                        this.telephone=res.detail.telephone;
                        get_login_code({
                            telephone:res.detail.telephone
                        }).then(
                            (res)=>{
                                if(res.status==0){
                                    this.$message.success(res.detail)
                                }else{
                                    this.$message.error(res.detail)
                                    clearInterval(this.timer)
                                    this.sendFlag=true
                                    this.timer = null
                                    this.code = '获取验证码'
                                }
                            }
                        )
                    }
                }
            )
            
            
        }
    }
}
</script>
 
<style lang = "less" scoped>
.login-page {
  width: 100vw;
  height: 100vh;
  background-color: #324057;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-container {
    width: 450px;
    height: 210px;
    .login-title {
      padding: 10px 0;
      color: #fff;
      font-size: 24px;
      text-align: center;
    }

    .login-submit-btn {
      width: 100%;
    }
  }
} 
.get-code-botton{
    position:absolute; 
    height: 40px;
    right: 5px;
    line-height: 10px;
    top: 10px;
    bottom: 0;
    margin: auto 0;
    border-radius: 20px;
}
/deep/.el-input__inner {
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 50px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    margin-top: 10px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;

}
</style>