<template>
  <div>
    <el-dialog title="编辑发票" :visible.sync="dialogVisible" :before-close="cancel">
        <el-form :model="form" ref="form" label-position="left" label-width="120px">
        <el-form-item label="状态">
            <el-select v-model="form.invoice_status" placeholder="请选择" clearable style="margin:0 10px" @change="selectChange">
                <el-option label="申请中" :value="1"></el-option>
                <el-option label="已申请" :value="2"></el-option>
                <el-option label="信息错误" :value="3"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="快递" prop="fedex">
            <el-input v-model="form.fedex" clearable></el-input>
        </el-form-item>        
        <el-form-item label="单号" prop="fedex_orderid">
            <el-input v-model="form.fedex_orderid" clearable></el-input>
        </el-form-item>

        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { invoice_edit} from "@/request/api.js";
export default {
    created(){
        // this.form.invoice_status=this.dialogData.invoice_status
        this.form={...this.dialogData}
        this.form.id=this.dialogData._id
        delete this.form._id
        console.log(this.dialogData);
        
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{},
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            invoice_edit(this.form).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success(res.detail)
                        this.$emit('freshData')
                        this.$emit('update:dialogVisible',false)
                    }
                }
            )
        },
        selectChange(val){
            console.log(val);
            
            this.form.invoice_status=val
        }
    }
}
</script>

<style lang = "less" scoped>
.el-input {
    width: 400px;
}
</style>