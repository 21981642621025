<template>
  <div class="userPage">
    <el-form :model="form" ref="form">
      <el-form-item label="用户查询" prop="user">
        <el-input style="width:200px" placeholder="请输入用户名或手机号" v-model="form.user" clearable></el-input>
      </el-form-item>

      <el-form-item label="用户来源" prop="source">
        <el-checkbox-group v-model="form.source">
          <el-checkbox label="android"></el-checkbox>
          <el-checkbox label="ios"></el-checkbox>
          <el-checkbox label="pc_client"></el-checkbox>
          <el-checkbox label="pc"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="购买套餐" prop="type">
        <el-checkbox-group v-model="form.type">
          <el-checkbox v-for="item in mealList" :key="item.id" :label="item.name"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="支付时间" prop="paytime">
        <el-date-picker
          v-model="form.paytime"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <el-button type="primary" @click="freshData" style="margin: 0 10px">查询</el-button>
        <el-button type="primary" @click="resetForm('form')">清空搜索条件</el-button>
        <el-button type="primary" @click="exportData('2')">导出表格</el-button>
      </el-form-item>

      <el-form-item label="下单时间" prop="createtime">
        <el-date-picker
          v-model="form.createtime"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
    </el-form>

    <!-- 表格数据 -->
    <el-table :data="tableData" stripe style="width:100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="orderid" label="订单号"></el-table-column>
      <el-table-column prop="user" label="用户名"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column prop="type" label="所选套餐"></el-table-column>
      <el-table-column prop="money" label="订单金额"></el-table-column>
      <el-table-column prop="pay_time" label="支付时间" :formatter="formatterTime"></el-table-column>
      <el-table-column prop="desc" label="付款方式"></el-table-column>
      <el-table-column prop="ip" label="下单ip"></el-table-column>
      <el-table-column prop="transaction_id" label="支付平台订单号"></el-table-column>
      <el-table-column prop='renew' label="购买类型" :formatter="formatterRenew"></el-table-column>
      <el-table-column prop="is_pay" label="订单状态" :formatter="formatterPay"></el-table-column>
      <el-table-column fixed="right"  prop="type" label="编辑操作">
        <template slot-scope="scope">
          <el-button v-if='scope.row.is_pay=="已支付"' type="text" @click="deleteId(scope.row.orderid)">退款</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { product_list, order_show, pay_status,order_export } from "@/request/api.js";

export default {
  created() {
    product_list().then(res => {
      if (res.status == 0) {
        this.mealList = [];
        res.detail.forEach(item => {
          if (item.is_published == 1) {
            this.mealList.push(item);
          }
        });
      }
    });

    this.freshData();
  },
  data() {
    return {
      mealList: [],
      form: {
        user: "",
        source: [],
        type: []
      },
      tableData: [],
      total: 0,
      limit: 10,
      page: 1
    };
  },
  methods: {
    //查询
    freshData() {
      let obj = { ...this.form };
      obj.limit = this.limit;
      obj.page = this.page;
      if (this.form.source.length > 0) {
        obj.source = this.form.source.join(",");
      } else {
        delete obj.source;
      }
      if (this.form.type.length > 0) {
        obj.type = this.form.type.join(",");
      } else {
        delete obj.type;
      }
      if (this.form.paytime) {
        obj.pay_start_time = this.form.paytime[0] ;
        obj.pay_end_time = this.form.paytime[1];
      }
      if (this.form.createtime) {
        obj.place_order_start = this.form.createtime[0];
        obj.place_order_end = this.form.createtime[1];
      }

      delete obj.paytime;
      delete obj.createtime;
      // console.log(obj);
      // return
      order_show(obj).then(res => {
        if (res.status == 0) {
          this.$message.success("获取成功");
          this.tableData = res.detail;
          this.total = res.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    changeSize(val) {
      this.limit = val;
      this.freshData();
    },
    changePage(val) {
      this.page = val;
      this.freshData();
    },
    //清空条件
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //导出表格
    exportData() {
      let obj = { ...this.form };
      obj.limit = this.limit;
      obj.page = this.page;
      if (this.form.source.length > 0) {
        obj.source = this.form.source.join(",");
      } else {
        delete obj.source;
      }
      if (this.form.type.length > 0) {
        obj.type = this.form.type.join(",");
      } else {
        delete obj.type;
      }
      if (this.form.paytime) {
        obj.pay_start_time = this.form.paytime[0] / 1000;
        obj.pay_end_time = this.form.paytime[1] / 1000;
      }
      if (this.form.createtime) {
        obj.place_order_start = this.form.createtime[0] / 1000;
        obj.place_order_end = this.form.createtime[1] / 1000;
      }

      delete obj.paytime;
      delete obj.createtime;
      order_export(obj)
    },
    //退款
    deleteId(id) {
      this.$alert("你正在进行退款操作", "提示", {
        confirmButtonText: "确定",
        callback: action => {
          if (action == "confirm") {
            pay_status({'orderid':id}).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.freshData()
                }
              }
            );

          }
        }
      });
    },
    formatterPay(row,column,cell){
      switch(row.is_pay){
        case 1:
          cell='未支付';
          break;        
        case 2:
          cell='已支付';
          break;        
        case 3:
          cell='已退款';
          break;
        default:;
      }
      return cell
    },
    formatterTime(row,column,cell){
      cell=row.pay_time?this.detailtime((row.pay_time).toString()) : ''
      return cell
    },
    formatterRenew(row,column,cell){
      cell=row.renew==1?'首次购买':'续费购买'
      return cell
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 30px;
}
.el-row {
  margin-bottom: 20px;
}
.el-checkbox-group {
  display: inline;
}
.el-form-item {
  margin: 0;
}
>>> .el-table th {
  user-select: text;
}
</style>
