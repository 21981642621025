<template>
  <div class='BanOperatorPage'>
    <p >可用运营商列表：<span style='color:red;font-weight:bold'>(其中红色字体的运营商代表被禁用)</span></p>
    <el-tag  :class='["mytag",selectArrColor.indexOf(item.name)>-1?"select":"",item.is_ban==1?"ban":""]' style='font-size:16px;margin:10px'  v-for="(item,idx) in list" :key='item._id' @click='edit(item,idx)'>{{item.name}}</el-tag>
    <el-row>
        <el-button style='margin:20px' @click='banOperator(1)'>禁用</el-button>
        <el-button style='margin:20px' @click='banOperator(0)'>解禁</el-button>
        <el-button style='margin:20px' @click='reset'>取消选中项</el-button>
    </el-row>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData' @selectChange="selectChange"/>
  </div>
</template>

<script>
import { operator_list ,ban_operator } from "@/request/api.js";
import Edit from "./components/BanOperator/edit.vue"
export default {
  components: {
    Edit
  },
  created() {
    this.freshData();
  },
  data() {
    return {
        list:[],
        select:[],
        selectArrColor:[],
        editDialogData: {}, //套餐编辑
        editDialogFlag: false,
    };
  },
  methods:{
    freshData(){
      operator_list().then(
        (res) => {
          if(res.status == 0) {
            this.$message.success('获取成功');
            this.list=res.detail
          }else{
            this.list=[]
          }
        }
      );
    },
    //编辑
    edit(row,idx) {
      console.log(row);
      this.editDialogFlag = true;
      this.editDialogData = row;
      if(!row.ban_list){
        row.ban_list=[]
      }
    },

    //禁用运营商
    banOperator(val){
      if(this.select.length==0){
        this.$message.error('请先选择运营商')
        return
      }
      this.select.forEach((item)=>{
        item.is_ban=val
      })
      console.log('最终==='+JSON.stringify(this.select));

      ban_operator({'suply':JSON.stringify(this.select)}).then(
        (res) => {
          if (res.status == 0) {
            this.reset()
            this.$message.success(res.detail);
            this.freshData()
          }
        }
      );

    },
    //被选中得平台
    selectChange(val){
      this.select.forEach((item,idx)=>{
        if(item.name==val.name){
          this.select.splice(idx,1)
        }
      })
      this.select.push({...val})
      this.selectArrColor.push(val.name)
      console.log(this.select);
    },
    reset(){
      this.selectArrColor=[]
      this.select=[]
    }

  }

};
</script>

<style scoped>
.checkbok {
  margin: 20px;
}
.ban {
  color: red;
}
.select{
  color: #51cd51;
}


</style>