<template>
  <div>
    <el-date-picker
      style="margin-top:20px"
      v-model="dateArr"
      format="yyyy-MM-dd"
      value-format="timestamp"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="freshData"
    ></el-date-picker>
    <span>此日期仅供表格导出</span>
    <el-button type="primary" icon="el-icon-search" @click="exportData">导出列表</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-form label-position="left" inline class="demo-table-expand">
              <h3>举报内容：</h3>
              <div>
                  <span>{{ scope.row.content }}</span>
              </div>
              <h3>举报图片</h3>
              <div v-for="(item,index) of scope.row.plink_arr.split(';')" :key="index">
                  <img  :src="'https://4g.4gdl.com/upload_image/'+item ">
              </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="report_date" label="日期"></el-table-column>
      <el-table-column prop="title" label="举报标题"></el-table-column>
      <el-table-column prop="username" label="用户"></el-table-column>
      <el-table-column prop="dev" label="客户端"></el-table-column>
      <el-table-column prop="channel" label="渠道来源"></el-table-column>
      <el-table-column  label="处理">
        <template slot-scope="scope">
          未解决<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>已解决
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {report_list} from "../../request/api.js"

export default {
  created(){
    this.freshData()
  },
  data() {
    return {
      limit: 10,
      page: 1,
      total:0,//总条数
      tableData: [],
      dateArr:[]

    };
  },
  methods: {
    freshData() {
      let obj = {}
      if(this.dateArr.length>0){
        obj.start_time=this.formmaterDate(this.dateArr[0])
        obj.end_time=this.formmaterDate(this.dateArr[1])
      }
      obj.limit=this.limit
      obj.page=this.page
      report_list(obj).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            this.tableData = res.detail;
            this.total = res.total;
            console.log('举报信息',res.detail)
          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );

    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //表格导出
    exportData(){

    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 20px;
}
>>> .el-table th {
  user-select: text;
}
</style>
