import qs from 'qs'
import axios from "axios"
import { Message } from 'element-ui'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 10000
})

instance.interceptors.request.use(config => {   
    if(config.method=='get'){
        config.url=config.url+'?'+qs.stringify(config.params)
        window.open(config.url)
    }   
    return config  
}, err => {
    return Promise.reject(err)
})

instance.interceptors.response.use(res => {
    let data = res.data  
    if(data.status==401){
        Message({
            message: "网络请求错误",
            type: 'error'
        });
    }
    if(data.status&&data.status!=0){
        Message({
            message: data.detail,
            type: 'error'
        });
    }
    return data
}, err => {
    return Promise.reject(err)
})
export default instance 