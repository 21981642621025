<template>
  <div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      :before-close="cancel">
        <el-form :model="form">
            <el-form-item label="用户名">
                <el-input v-model="form.username" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
                <el-input v-model="form.mobile" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="永久禁止">
                <el-radio-group v-model="form.forbidden">
                    <el-radio :label="1">禁用</el-radio>
                    <el-radio :label="0">不禁用</el-radio>
                </el-radio-group>
            </el-form-item>            
            <el-form-item label="禁用续费"> 
                <el-radio-group v-model="form.renew_disable">
                    <el-radio :label="1">禁用</el-radio>
                    <el-radio :label="0">不禁用</el-radio>
                </el-radio-group>
            </el-form-item>            
            <el-form-item label="临时禁止">
                <el-radio-group v-model="form.temporary_forbidden">
                    <el-radio :label="1">禁用</el-radio>
                    <el-radio :label="0">不禁用</el-radio>
                </el-radio-group>
            </el-form-item>            
            <el-form-item label="屏蔽已添加的域名和IP">
                <el-radio-group v-model="form.commbaned">
                    <el-radio :label="1">禁用</el-radio>
                    <el-radio :label="0">不禁用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="选择禁用时间段">
                <el-date-picker
                    v-model="form.ban_time"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择日期时间"
                >
                </el-date-picker>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { user_edit } from "@/request/api.js";
export default {
    created(){
        this.form={...this.dialogData}
        this.form.password=''
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{}
        }
    },
    methods:{
        cancel(){
            this.form={}
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            let obj={
                username:this.form.username,
                mobile:this.form.mobile,
                forbidden:this.form.forbidden,
                renew_disable:this.form.renew_disable,
                temporary_forbidden:this.form.temporary_forbidden,
                commbaned:this.form.commbaned,
                ban_time:this.form.ban_time?this.form.ban_time:''
            }
            if(this.form.password){
                obj.password=this.form.password
            }
            console.log(`form`+JSON.stringify(obj));
            user_edit(obj).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success(res.detail)
                        this.$emit('update:dialogVisible',false)
                        this.$emit('freshData')
                    }
                }
            )
        }
    }
}
</script>

<style>

</style>