<template>
  <div>
    <el-row>
        <el-select v-model="selectMeal" placeholder="请选择" clearable @change="selectChange">
            <el-option
            v-for="item in mealList"
            :key="item"
            :value="item">
            </el-option>
        </el-select>
        <el-button type='primary' @click='addDialogFlag=true'>新增优惠券</el-button>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="_id" label="ID"></el-table-column>
      <el-table-column prop="discount_name" label="优惠券名称"></el-table-column>
      <el-table-column prop="meal_type" label="可用套餐"></el-table-column>
      <el-table-column prop="user_type" label="可用用户"></el-table-column>
      <el-table-column prop="minus_price" label="优惠券金额（元）"></el-table-column>
      <el-table-column prop="full_reduction" label="最低订单金额（元）"></el-table-column>
      <el-table-column prop="type" label="有效期"></el-table-column>
      <!-- <el-table-column prop="type" label="过期类型"></el-table-column> -->
      <!-- <el-table-column label="可领取时间">
          <template slot-scope="scope">
            <p>{{scope.row.start_time }}</p>
            至
            <p>{{scope.row.end_time }}</p>
          </template>
      </el-table-column> -->
      <el-table-column prop="pc" label="备注"></el-table-column>
      <el-table-column prop="status" label="当前状态">
          <template slot-scope="scope">
              <el-switch 
                v-model="scope.row.status" 
                active-color="#13ce66"
                inactive-color="#ccc"
                :active-value="2"
                :inactive-value="1"
                 @change='switchChange(scope.row)'
              >
              </el-switch>
          </template>
      </el-table-column>
      <el-table-column label="操作" width='200'>
          <template slot-scope="scope">
              <el-button @click='edit(scope.row)'>编辑</el-button>
              <el-button @click='deleteID(scope.row.code)'>删除</el-button>
          </template>
      </el-table-column>
    </el-table>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>    
    <!-- 新增 -->
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" @freshData='freshData'/>
    
  </div>
</template>

<script>
import { discount_coupon_list,discount_coupon_del,discount_coupon,coupon_status } from "@/request/api.js";
import Edit from './components/Coupon/edit.vue'
import Add from './components/Coupon/add.vue'
export default {
    components:{
        Edit,
        Add
    },
    created(){
        this.freshData()
    },
    data(){
        return {
            mealList:['普通套餐','高级套餐','加油包'],            
            selectMeal:'',
            tableData:[],
            editDialogData:{},//套餐编辑
            editDialogFlag:false,    
            addDialogFlag:false,   
        }
    },
    methods:{
        freshData(){
            let obj={}
            if(this.selectMeal){
                obj.type=this.selectMeal
            }
            discount_coupon_list(obj).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success('获取成功')
                        res.detail.forEach((item)=>{

                            if(item.date_type==1){
                                // 领取多少天后可用
                                item.type=`领取后${item.valid_day}天过期`
                            }else{
                                //
                                item.type=`${this.detailtime(item.start_time*1000)}至${this.detailtime(item.end_time*1000)}可用`
                            }
                            item.start_time=this.detailtime(item.start_time*1000)
                            item.end_time=this.detailtime(item.end_time*1000)
                        })
                        this.tableData=res.detail
                    }else{
                        this.tableData=[]
                    }
                }
            )
        },
        selectChange(val){
            this.selectMeal=val
            this.freshData()
        },
        switchChange(val){
            let obj = {
                status:val.status,
                id:val._id
            }
            // console.log(obj)
            // return
            coupon_status(obj).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.freshData()
                }
              }
            );
        },
        //套餐编辑
        edit(row){
            this.editDialogFlag=true;
            this.editDialogData=row;
        },
        deleteID(code){
            // console.log(id);
            this.$alert('你正在进行删除优惠券操作', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if(action=='confirm'){
                        discount_coupon_del({code}).then(
                            (res)=>{
                                if(res.status==0){
                                    this.$message.success(res.detail)
                                    this.freshData()
                                }
                            }
                        )
                        
                    }
                }
            });
        },

    }

}
</script>

<style>

</style>