import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from "js-cookie";

import './plugins/element'
require('./plugins/mock.js')

Vue.config.productionTip = false
Vue.prototype.detailtime=function (timestamp) {
  if(timestamp.length==10){
    timestamp=timestamp*1000
  }
  var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate() + ' '
  var h = date.getHours() < 10 ? '0' + (date.getHours()) : (date.getHours())
  var m = date.getMinutes() < 10 ? '0' + (date.getMinutes()) : (date.getMinutes())
  var s = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : (date.getSeconds())
  return Y + M + D + h + ':' + m + ':' + s
}
Vue.prototype.formmaterDate=function (timestamp) {
  if(timestamp.length==10){
    timestamp=timestamp*1000
  }
  var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate()  < 10 ? '0' + date.getDate() : date.getDate() 
  return Y + M + D 
}
Vue.prototype.$cookies=Cookies
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
