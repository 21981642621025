<template>
  <div>
    <el-form :model="form" ref="form" label-position="left" label-width="120px">
      <el-form-item label="供应商">
        <el-select v-model="form.suply" placeholder="全部" clearable>
          <el-option label="PC端" value="PC端"></el-option>
          <el-option label="android端" value="android端"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.online" placeholder="全部" clearable>
          <el-option label="在线" value="$gt"></el-option>
          <el-option label="离线" value="$lt"></el-option>
        </el-select>
        <el-button @click="freshData" type="primary" style='margin-left:20px'>查询</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="user_count" sortable label="实时连接数"></el-table-column>
      <el-table-column prop="cloud_server" label="云主机名"></el-table-column>
      <el-table-column prop="suply" label="供应商"></el-table-column>
      <el-table-column prop="p_ip" label="公网ip"></el-table-column>
      <el-table-column prop="serial" label="序列号"></el-table-column>
      <el-table-column prop="port" label="端口"></el-table-column>
      <el-table-column prop="city" label="地区"></el-table-column>
      <el-table-column prop="isp_addr" label="运营商"></el-table-column>
      <el-table-column prop="flow" label="手机卡总流量"></el-table-column>
      <el-table-column prop="flow_left" label="剩余流量">
          <template slot-scope="scope">
            <span>{{(scope.row.flow*10000-scope.row.flow_used*10000)/10000}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="flow_date" label="月结日期"></el-table-column>
      <el-table-column prop="mobile" label="手机号"></el-table-column>
      <el-table-column prop="vip" label="节点类型">
          <template slot-scope="scope">
            <span>{{scope.row.vip == 2 ? '专线' : '共享'}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="is_online" label="当前状态">
          <template slot-scope="scope">
            <span>
                <el-button v-if="((scope.row.time + 180) > (+new Date())/1000)" type="success">在线</el-button>
                <el-button v-if="((scope.row.time + 180) <= (+new Date())/1000)" type="danger">离线</el-button>
            </span>
          </template>
      </el-table-column>
      <el-table-column prop="recode" label="是否可使用">
          <template slot-scope="scope">
            <span :style='{color:(scope.row.recode == 2?"red":"")}'>{{scope.row.recode == 2 ? '使用中' : '可使用'}}</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)">编辑</el-button>
          <el-button @click="deleteID(scope.row._id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>
  </div>
</template>

<script>
import { dynamic_vps, dynamic_vps_delete } from "@/request/api.js";
import Edit from "./components/DynamicNode/edit.vue";
export default {
  components: {
    Edit
  },
  created(){
    this.freshData()
  },
  data() {
    return {
      form: {},
      tableData: [],
      editDialogData: {}, //套餐编辑
      editDialogFlag: false,
      addDialogFlag: false
    };
  },
  methods: {
    freshData(){
      // console.log(this.form);
      // return
      dynamic_vps(this.form).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            this.tableData = res.detail;
            this.total = res.total;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );
    },
    //编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 删除
    deleteID(id) {
      // console.log(id);
      // return
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: action => {
          if(action=='confirm'){
            dynamic_vps_delete({id}).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.freshData()
                }
              }
            );
          }
        }
      });
    }
  }
};
</script>

<style>
</style>