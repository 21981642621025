export default{
    namespaced:true,
    state: {
        userPathList:[]    // 用户的权限列表，一旦登录就更新这里的数组
    },
    mutations: {
        changeUserPathList(state, payload){
            state.userPathList = payload
        }
    },
    actions: {
    },
}