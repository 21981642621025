<template>
  <div>
    <el-date-picker
      style="margin-top:20px"
      v-model="dateArr"
      format="yyyy-MM-dd"
      type="daterange"
      value-format="timestamp"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="freshData"
    ></el-date-picker>
    <span>此日期仅供表格导出</span>
    <el-button type="primary" icon="el-icon-search" @click="exportData">导出列表</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="date" label="日期"></el-table-column>
      <el-table-column prop="all_list" label="总登录数/去重/新用户/老用户"></el-table-column>
      <el-table-column prop="pc_list" label="windos登录数/去重/新用户/老用户"></el-table-column>
      <el-table-column prop="web_list" label="网页登录数/去重/新用户/老用户"></el-table-column>
      <el-table-column prop="android_list" label="android登录数/去重/新用户/老用户"></el-table-column>
      <el-table-column prop="ios_list" label="ios登录数/去重/新用户/老用户"></el-table-column>
    </el-table>
    
    <!--分页器 -->
    <!-- <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page_current"
      :page-sizes="[20, 50, 300, 500]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination> -->
  </div>
</template>

<script>
import { every_login_data} from "@/request/api.js";
export default {
  created(){
    this.freshData()
  },
  data() {
    return {
      dateArr:'',
      tableData: [],
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
    };
  },

  methods: {
    freshData(){
      every_login_data().then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            res.detail.forEach(item=>{
              item.all_list=`${item.login_total??"0"}/${item.login_total_distinct??"0"}/${item.new_user??"0"}/${item.old_user??"0"}`

              item.pc_list=`${item.pc_client??"0"}/${item.pc_client_distinct??"0"}/${item.pc_client_new??"0"}/${item.pc_client_old??"0"}`

              item.web_list=`${item.pc??"0"}/${item.pc_distinct??"0"}/${item.pc_new??"0"}/${item.pc_old??"0"}`

              item.android_list=`${item.android??"0"}/${item.android_distinct??"0"}/${item.android_new??"0"}/${item.android_old??"0"}`

              item.ios_list=`${item.ios??"0"}/${item.ios_distinct??"0"}/${item.ios_new??"0"}/${item.ios_old??"0"}`
            })
            this.tableData=res.detail

          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //表格导出
    exportData() {
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 20px;
}
>>> .el-table th {
  user-select: text;
}
</style>
