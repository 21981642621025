<template>
  <div>
    <el-row>
        <el-select v-model="selectMeal" placeholder="请选择" clearable @change="selectChange">
            <el-option
                v-for="item in mealList"
                :key='item'
                :label="item"
                :value='item'
            >
            </el-option>
        </el-select>
        <p></p>
        <el-button type='primary' @click='addDialogFlag=true' style='margin-top:20px'>新增套餐</el-button>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="_id" label="套餐ID"></el-table-column>
      <el-table-column prop="type" label="套餐类型"></el-table-column>
      <el-table-column prop="name" label="套餐名称"></el-table-column>
      <el-table-column prop="flow" label="套餐流量（GB）"></el-table-column>
      <el-table-column prop="long" label="套餐时长（天）"></el-table-column>
      <el-table-column prop="web_price" label="官网套餐价格（元）"></el-table-column>
      <el-table-column prop="pc_price" label="PC端套餐价格（元）"></el-table-column>
      <el-table-column prop="android_price" label="Android端套餐价格（元）"></el-table-column>
      <el-table-column prop="ios_price" label="ios端套餐价格（元）"></el-table-column>
      <el-table-column prop="pc_original" label="原价（元）"></el-table-column>
      <el-table-column prop="online_price" label="在线数单价（元）"></el-table-column>
      <el-table-column prop="tag" label="标签名"></el-table-column>
      <el-table-column prop="note" label="备注"></el-table-column>
      <el-table-column prop="is_published" label="当前状态">
          <template slot-scope="scope">
              <el-switch 
                v-model="scope.row.is_published" 
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                inactive-color="#ccc"
                @change='switchChange(scope.row)'
              >
              </el-switch>
          </template>
      </el-table-column>
      <el-table-column label="操作" width='200'>
          <template slot-scope="scope">
              <el-button @click='edit(scope.row)'>编辑</el-button>
              <el-button @click='deleteID(scope.row._id)'>删除</el-button>
          </template>
      </el-table-column>
    </el-table>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>    
    <!-- 新增套餐 -->
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" @freshData='freshData'/>
    
  </div>
</template>

<script>
import { product_list,meal_delete,meal_edit } from "@/request/api.js";
import Edit from './components/MealManagement/edit.vue'
import Add from './components/MealManagement/add.vue'
export default {
    components:{
        Edit,
        Add
    },
    created(){
        this.freshData()
    },
    data(){
        return {
            mealList:['全部套餐','普通套餐','高级套餐','加油包'],            
            selectMeal:'',
            tableData:[],
            editDialogData:{},//套餐编辑
            editDialogFlag:false,    
            addDialogFlag:false,   
        }
    },
    methods:{
        selectChange(val){
            this.selectMeal=val
            this.freshData()
        },
        switchChange(val){
            let obj = {...val}
            obj.id=val._id
            delete obj._id
            meal_edit(obj).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.freshData()
                }
              }
            );
        },
        freshData(){
            let obj={}
            if(this.selectMeal&&this.selectMeal!='全部套餐'){
                obj.type=this.selectMeal
            }
            product_list(obj).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success('套餐列表获取成功')
                        this.tableData=res.detail
                    }else{
                        this.tableData=[];
                        this.$message.error(res.detail)
                    }
                }
            )
        },
        //套餐编辑
        edit(row){
            this.editDialogFlag=true;
            this.editDialogData=row;
        },
        deleteID(id){
            // console.log(id);
            this.$alert('你正在进行删除套餐操作', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if(action=='confirm'){
                        meal_delete({
                            id
                        }).then(
                            (res)=>{
                                if(res.status==0){
                                    this.$message.success(res.detail)
                                    this.freshData()
                                }
                            }
                        )
                    }
                }
            });
        }
    }
}
</script>

<style>

</style>