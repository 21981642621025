<template>
  <div>
    <el-form :model="form" ref="form" label-position="left" label-width="150px">
      <el-form-item label="接口服务商">
        <el-select v-model="form.interface_support" placeholder="请选择" clearable >
          <el-option label="天眼" value="ty"></el-option>
          <el-option label="数据宝" value="sjb"></el-option>
          <el-option label="微信" value="wx"></el-option>
          <el-option label="支付宝" value="zfb"></el-option>
        </el-select>
      </el-form-item>      
      <el-form-item label="实名时间">
        <el-date-picker
          v-model="form.time"
          format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item  label="用户查询" prop='username'>
          <el-input
            style='width:200px'
            placeholder="请输入用户名或手机号"
            v-model="form.username"
            clearable>
          </el-input>
      <el-button type="primary" style='margin: 0 20px' @click='freshData'>查询</el-button>
      </el-form-item>
    </el-form>
    
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="interface_support" label="接口服务商" :formatter="formatter"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="cert_mobile" label="实名手机号"></el-table-column>
      <el-table-column prop="cert_time" label="实名时间"></el-table-column>
      <el-table-column label="接口返回数据">
        <template slot-scope="scope">
          <el-button @click="look(scope.row)">查看</el-button>
        </template>  
      </el-table-column>
      <el-table-column prop="order_no" label="数据凭证"></el-table-column>
      <el-table-column prop="time_count" label="请求时间"></el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <el-dialog title="查看" :visible.sync="dialogVisible" >
      <div>{{detail}}</div>
    </el-dialog>
  </div>
</template>

<script>
import { cert_record } from "@/request/api.js";

export default {
  created(){
    this.freshData()
  },
  data() {
    return {
      form: {},
      tableData: [],
      editDialogData: {}, //套餐编辑
      editDialogFlag: false,
      addDialogFlag: false,
      detail:'',//对应的接口详情
      dialogVisible:false,
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
    };
  },
  methods: {
    freshData(){
      let obj = {...this.form}
      if(this.form.time){
        obj.cert_time_begin=this.formmaterDate(this.form.time[0])
        obj.cert_time_end=this.formmaterDate(this.form.time[1])
      }
      obj.page=this.page
      obj.limit=this.limit
      delete obj.time

      cert_record(obj).then(
        (res)=>{
          if(res.status==0){
            this.$message.success('获取成功')
            this.tableData=res.detail
            this.total=res.total
          }else{
            this.tableData=[]
            this.total=0
          }
        }
      )
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    look(row){
      this.dialogVisible=true
      this.detail=row
    },
    formatter(row){
      switch(row.interface_support){
        case 'wx':
           row.interface_support='微信'
           break;        
        case 'zfb':
           row.interface_support='支付宝'
           break;        
        case 'sjb':
           row.interface_support='数据宝'
           break;               
        case 'ty':
           row.interface_support='天眼'
           break;        
        default:;
      }
      return row.interface_support
    }
  }
};
</script>

<style>
</style>