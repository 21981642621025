// 引入mockjs
const Mock = require('mockjs');
// 获取 mock.Random 对象
const Random = Mock.Random;

// let newArticleObject = {
//     title: Random.csentence(5, 30), //  Random.csentence( min, max ) 随机获取5-30个文字
//     thumbnail_pic_s: Random.dataImage('300x250', 'mock的图片'), // Random.dataImage( size, text ) 生成一段随机的 Base64 图片编码
//     author_name: Random.cname(), // Random.cname() 随机生成一个常见的中文姓名
//     date: Random.date() + ' ' + Random.time() // Random.date()指示生成的日期字符串的格式,默认为yyyy-MM-dd；Random.time() 返回一个随机的时间字符串
// }

// Random.datetime('yyyy-MM-dd hh:mm:ss'); //格式化日期
// Random.province(); //省
// Random.city(); //市
// Random.county(); //区县
// mock一组数据

Mock.Random.extend({
    phone: function () {
        var phonePrefixs = ['132', '135', '189', '184', '151', '136', '178', '183', '186', '131', '158', '188'] // 自己写前缀哈
        return this.pick(phonePrefixs) + Mock.mock(/\d{8}/) //Number()
    },
    client: function (par) {
        let constellations = par ? ['PC', 'Android', 'IOS'] : ['节点繁忙', '自动掉线', '节点上限', '节点无法连接', '充值问题', '其他问题']
        //随机选择2个作为返回值
        return this.pick(constellations, 1)
        //随机选择2-4个作为返回值
        //    return this.pick(this.constellations, 2, 4)
    },
})
// 版本统计
const VersionStatisticsData = function(){
    let articles = [];
    for (let i = 0; i < 50; i++) {
        let newArticleObject = {
            "feedback-type": Random.client(true),
            version:"2.0.1."+((Math.floor(Math.random()*50)+1)),
            count:Math.floor(Math.random()*1000)+1
        }
        articles.push(newArticleObject)
    }

    return {
        articles: articles
    }
}
// 意见反馈
const FeedbackData = function () {
    let articles = [];
    for (let i = 0; i < 70; i++) {
        let newArticleObject = {
            username: Random.cname(),
            phone: Mock.Random.phone(),
            client: Mock.Random.client(true),
            "feedback-type": Random.client(false),
            content: Random.csentence(5, 100),
            contact: Mock.Random.phone()
        }
        articles.push(newArticleObject)
    }

    return {
        articles: articles
    }
}

// Mock.mock( url, post/get , 返回的数据)；
Mock.mock('/VersionStatistics/data', 'post', VersionStatisticsData);
Mock.mock('/feedback/data', 'post', FeedbackData);