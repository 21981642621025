<template>
  <div>
    <el-form :model="form" ref="form" label-position="left" label-width="120px">
      <el-form-item label="审核状态">
        <el-select v-model="form.status" placeholder="请选择" clearable style="margin:0 10px">
          <el-option label="未提交" :value="0"></el-option>
          <el-option label="审核中" :value="1"></el-option>
          <el-option label="审核成功" :value="2"></el-option>
          <el-option label="审核失败" :value="3"></el-option>
        </el-select>
      </el-form-item>      
      <el-form-item label="实名日期">
        <el-date-picker
          v-model="form.time"
          format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
    </el-form>

    <el-button type="primary" @click='freshData'>查询</el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="mobile" label="用户手机号"></el-table-column>
      <el-table-column prop="idverify_date" label="实名时间"></el-table-column>
      <el-table-column prop="status" label="审核状态" :formatter="formatter"></el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>
    
  </div>
</template>

<script>
import { enterprise_idverify } from "@/request/api.js";

import Edit from "./components/IdverifyManagement/edit.vue";
export default {
  components: {
    Edit
  },
  created(){
    this.freshData()
  },
  data() {
    return {
      form: {},
      tableData: [],
      editDialogData: {}, //套餐编辑
      editDialogFlag: false,
      addDialogFlag: false,
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
    };
  },
  methods: {
    freshData(){
      let obj = {...this.form}
      if(this.form.time){
        obj.cert_time_begin=this.formmaterDate(this.form.time[0])
        obj.cert_time_end=this.formmaterDate(this.form.time[1])
      }
      obj.page=this.page
      obj.limit=this.limit
      delete obj.time

      enterprise_idverify(obj).then(
        (res)=>{
          if(res.status==0){
            this.$message.success('获取成功')
            this.tableData=res.detail
            this.total=res.total
          }else{
            this.tableData=[]
            this.total=0
          }
        }
      )
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    formatter(row,column,cell){
      switch(cell){
        case 0:
           cell='未提交'
           break;        
        case 1:
           cell='审核中'
           break;        
        case 2:
           cell='审核成功'
           break;               
        case 3:
           cell='审核失败'
           break;        
        default:;
      }
      return cell
    }
  }
};
</script>

<style>
</style>