<template>
  <div>
    <el-dialog title="企业实名信息" :visible.sync="dialogVisible" :before-close="cancel">
        <el-form :model="form" ref="form" label-position="left" label-width="120px">
            <el-form-item label="法人姓名" prop="name">
                <el-input v-model="form.name" clearable disabled></el-input>
            </el-form-item>        
            <el-form-item label="身份证号" prop="idcard">
                <el-input v-model="form.idcard" clearable disabled></el-input>
            </el-form-item>            
            <el-form-item label='身份证照'>
                <div style='display:flex'>
                    <div>
                        <p>身份证国徽面</p>
                        <img :src="form.obverse" alt="" width="150px" height="150px" @click='openInner(form.obverse)'>
                    </div>                    
                    <div style='margin-left:30px'>
                        <p>身份证人像面</p>
                        <img :src="form.reverse" alt="" width="150px" height="150px" @click='openInner(form.reverse)'>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="公司名称" prop="company_name" >
                <el-input v-model="form.company_name" clearable disabled></el-input>
            </el-form-item>            
            <el-form-item label="统一代码" prop="credit_code" >
                <el-input v-model="form.credit_code" clearable disabled></el-input>
            </el-form-item>            
            <el-form-item label="营业执照" prop="company_img">
                <img :src="form.company_img" alt="" width="120px" height="120px" @click='openInner(form.company_img)'>
            </el-form-item>            
            <el-form-item label="审核" prop="status">
                <el-radio-group v-model="form.status">
                    <el-radio :label="3">不通过</el-radio>
                    <el-radio :label="2">通过</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
      <el-dialog
        title="图片查看"
        :visible.sync="innerVisible"
        append-to-body>
        <img :src="imgSrc" alt="" width="600px" >
    </el-dialog>

    </el-dialog>
  </div>
</template>

<script>
import { enterprise_edit } from "@/request/api.js";

export default {
    created(){
        this.form={...this.dialogData}
        this.form.id=this.dialogData._id
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{},
            innerVisible:false,
            imgSrc:''
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            if(!this.form.id || !this.form.status){
                this.$message.succcess('请选择审核状态')
                return
            }
            enterprise_edit({username:this.form.username,id:this.form.id,status:this.form.status}).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.$emit('freshData')
                  this.$emit('update:dialogVisible',false)
                }
              }
            );
        },
        openInner(src){
            this.innerVisible=true
            this.imgSrc=src
        }
    }
}
</script>

<style lang = "less" scoped>
.el-input {
    width: 400px;
}
/deep/.el-dialog__body{text-align: center;}
</style>