<template>
  <div>
    <el-dialog
      title="套餐详情"
      :visible.sync="dialogVisible"
      :before-close="cancel">
        <!-- 表格数据 -->
        <el-table :data="tableData" stripe style="width:100%">
        <el-table-column
            type="index"
            label="序号">
        </el-table-column>
        <el-table-column
            prop="begin_time"
            label="套餐购买时间"
            :formatter="(row)=>{return this.detailtime(row.begin_time*1000)}"
        >
        </el-table-column>     
        <el-table-column
            prop="type"
            label="套餐类型">
        </el-table-column>      
        <el-table-column
            prop="meal_name"
            label="套餐名称">
        </el-table-column>
        <el-table-column
            prop="pay_method"
            label="付款方式">
        </el-table-column>      
        <el-table-column
            prop="dev"
            label="订单来源">
        </el-table-column>        
        <el-table-column
            prop="status"
            label="套餐状态">
        </el-table-column>
        <el-table-column
            prop="flow_surplus"
            label="套餐剩余流量">
        </el-table-column>
        <el-table-column
            prop="online"
            label="套餐在线数">
        </el-table-column>      
        <el-table-column
            prop="use"
            label="套餐使用状态（切换套餐状态）"
        >
        </el-table-column>
        <el-table-column
            width="100"
            prop="type"
            label="操作">
            <template slot-scope="scope">
                <el-button type="text" @click="readyToEdit(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
        </el-table>

        <Meal-edit v-if="MealEditFlag" :dialogVisible.sync="MealEditFlag" :dialogData="MealEditData" @freshData='freshData' />     

    </el-dialog>
  </div>
</template>

<script>
import { user_buy} from "@/request/api.js";
import MealEdit from './mealEdit.vue'
export default {
    components:{
        MealEdit
    },
    created(){
        this.form={...this.dialogData}
        this.freshData()
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            tableData:[],
            MealEditFlag:false,
            MealEditData:{},
        }
    },
    methods:{
        freshData(){
            user_buy({
                username:this.form.username
            }).then(
                (res)=>{
                    if(res.status==0&res.detail.length>0){
                        res.detail.forEach((item)=>{
                            item.use=item.use==1?"使用中":"非使用中"
                        })
                        this.tableData=res.detail
                    }
                }
            )
        },
        cancel(){
             this.$emit('update:dialogVisible',false)
        },
        submit(){
            this.$emit('update:dialogVisible',false)
        },
        readyToEdit(row){
            console.log(row);
            this.MealEditFlag=true;
            this.MealEditData=row;
        }
    }
}
</script>

<style>

</style>