import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import { Message } from 'element-ui';
import Cookies from "js-cookie";


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => {})
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    children:[
      //#region 服务器与节点====================================================================
      {
        path: '/DynamicNode',
        name: 'DynamicNode',
        meta:{
          catalog :"服务器与节点",
          target:"动态服务器"
        },
        component: () => import(/* webpackChunkName: "DynamicNode" */ '../components/server-node/DynamicNode.vue')
      },      
      {
        path: '/StaticNode',
        name: 'StaticNode',
        meta:{
          catalog :"服务器与节点",
          target:"静态服务器"
        },
        component: () => import(/* webpackChunkName: "StaticNode" */ '../components/server-node/StaticNode.vue')
      },      
      {
        path: '/BanOperator',
        name: 'BanOperator',
        meta:{
          catalog :"服务器与节点",
          target:"运营商禁用"
        },
        component: () => import(/* webpackChunkName: "BanOperator" */ '../components/server-node/BanOperator.vue')
      },      
      {
        path: '/DomainNameDisable',
        name: 'DomainNameDisable',
        meta:{
          catalog :"服务器与节点",
          target:"IP与域名禁用"
        },
        component: () => import(/* webpackChunkName: "DomainNameDisable" */ '../components/server-node/DomainNameDisable.vue')
      },
      //#endregion 服务器与节点====================================================================


      //#region 客户端与内嵌网页管理====================================================================
      {
        path: '/VersionStatistics',
        name: 'VersionStatistics',
        meta:{
          catalog :"客户端与内嵌网页管理",
          target:"版本统计"
        },
        component: () => import(/* webpackChunkName: "versionStatistics" */ '../components/client-embedded-web-management/VersionStatistics.vue')
      },
      {
        path: '/Feedback',
        name: 'Feedback',
        meta:{
          catalog :"客户端与内嵌网页管理",
          target:"意见反馈"
        },
        component: () => import(/* webpackChunkName: "feedback" */ '../components/client-embedded-web-management/Feedback.vue')
      },
      {
        path: '/ReportMessage',
        name: 'ReportMessage',
        meta:{
          catalog :"客户端与内嵌网页管理",
          target:"举报留言"
        },
        component: () => import(/* webpackChunkName: "reportMessage" */ '../components/client-embedded-web-management/ReportMessage.vue')
      },
      //#endregion 客户端与内嵌网页管理====================================================================

      //#region 用户管理====================================================================
      {
        path: '/User',
        name: 'User',
        meta:{
          catalog :"用户管理",
          target:"全部用户信息"
        },
        component: () => import(/* webpackChunkName: "User" */ '../components/user-management/User.vue')
      },
      //#endregion 用户管理====================================================================

      //#region 用户信息查询====================================================================
      {
        path: '/UserInfo',
        name: 'UserInfo',
        meta:{
          catalog :"用户信息查询",
          target:"用户详细信息"
        },
        component: () => import(/* webpackChunkName: "UserInfo" */ '../components/user-query/UserInfo.vue')
      },
      //#endregion 用户信息查询====================================================================

      //#region 数据统计与报表====================================================================
      {
        path: '/DownloadStatistical',
        name: 'DownloadStatistical',
        meta:{
          catalog :"数据统计与报表",
          target:"下载统计"
        },
        component: () => import(/* webpackChunkName: "DownloadStatistical" */ '../components/data-statistical-report/DownloadStatistical.vue')
      },      
      {
        path: '/InstallStatistical',
        name: 'InstallStatistical',
        meta:{
          catalog :"数据统计与报表",
          target:"安装统计"
        },
        component: () => import(/* webpackChunkName: "InstallStatistical" */ '../components/data-statistical-report/InstallStatistical.vue')
      },      
      {
        path: '/RegisterStatistical',
        name: 'RegisterStatistical',
        meta:{
          catalog :"数据统计与报表",
          target:"注册统计"
        },
        component: () => import(/* webpackChunkName: "RegisterStatistical" */ '../components/data-statistical-report/RegisterStatistical.vue')
      },      
      {
        path: '/LoginStatistical',
        name: 'LoginStatistical',
        meta:{
          catalog :"数据统计与报表",
          target:"用户登录统计"
        },
        component: () => import(/* webpackChunkName: "LoginStatistical" */ '../components/data-statistical-report/LoginStatistical.vue')
      },      
      {
        path: '/PayStatistical',
        name: 'PayStatistical',
        meta:{
          catalog :"数据统计与报表",
          target:"用户付费统计"
        },
        component: () => import(/* webpackChunkName: "PayStatistical" */ '../components/data-statistical-report/PayStatistical.vue')
      },      
      {
        path: '/RetainStatiscal',
        name: 'RetainStatiscal',
        meta:{
          catalog :"数据统计与报表",
          target:"用户留存统计"
        },
        component: () => import(/* webpackChunkName: "PayStatistical" */ '../components/data-statistical-report/RetainStatiscal.vue')
      },      
      {
        path: '/RenewStatiscal',
        name: 'RetainStaRenewStatiscaltiscal',
        meta:{
          catalog :"数据统计与报表",
          target:"用户续费统计"
        },
        component: () => import(/* webpackChunkName: "PayStatistical" */ '../components/data-statistical-report/RenewStatiscal.vue')
      },
      //#endregion 数据统计与报表====================================================================

      //#region 产品管理====================================================================
      {
        path: '/MealManagement',
        name: 'MealManagement',
        meta:{
          catalog :"产品管理",
          target:"套餐管理"
        },
        component: () => import(/* webpackChunkName: "MealManagement" */ '../components/product-management/MealManagement.vue')
      },      
      {
        path: '/Coupon',
        name: 'Coupon',
        meta:{
          catalog :"产品管理",
          target:"优惠券管理"
        },
        component: () => import(/* webpackChunkName: "Coupon" */ '../components/product-management/Coupon.vue')
      },      
      {
        path: '/Announcement',
        name: 'Announcement',
        meta:{
          catalog :"产品管理",
          target:"公告管理"
        },
        component: () => import(/* webpackChunkName: "Announcement" */ '../components/product-management/Announcement.vue')
      },      
      {
        path: '/BannerManagenment',
        name: 'BannerManagenment',
        meta:{
          catalog :"产品管理",
          target:"banner图管理"
        },
        component: () => import(/* webpackChunkName: "BannerManagenment" */ '../components/product-management/BannerManagenment.vue')
      },      
      {
        path: '/VersionUpdate',
        name: 'VersionUpdate',
        meta:{
          catalog :"产品管理",
          target:"版本更新"
        },
        component: () => import(/* webpackChunkName: "VersionUpdate" */ '../components/product-management/VersionUpdate.vue')
      },      
      {
        path: '/DownloadManagement',
        name: 'DownloadManagement',
        meta:{
          catalog :"产品管理",
          target:"下载链接管理"
        },
        component: () => import(/* webpackChunkName: "DownloadManagement" */ '../components/product-management/DownloadManagement.vue')
      },      
      {
        path: '/OrderManagement',
        name: 'OrderManagement',
        meta:{
          catalog :"产品管理",
          target:"订单管理"
        },
        component: () => import(/* webpackChunkName: "OrderManagement" */ '../components/product-management/OrderManagement.vue')
      },      
      {
        path: '/Refund',
        name: 'Refund',
        meta:{
          catalog :"产品管理",
          target:"退款详情"
        },
        component: () => import(/* webpackChunkName: "Refund" */ '../components/product-management/Refund.vue')
      },      
      // {
      //   path: '/RedeemManagement',
      //   name: 'RedeemManagement',
      //   meta:{
      //     catalog :"产品管理",
      //     target:"兑换码生成"
      //   },
      //   component: () => import(/* webpackChunkName: "RedeemManagement" */ '../components/product-management/RedeemManagement.vue')
      // },       
      {
        path: '/RedeemRecord',
        name: 'RedeemRecord',
        meta:{
          catalog :"产品管理",
          target:"兑换码使用记录"
        },
        component: () => import(/* webpackChunkName: "RedeemRecord" */ '../components/product-management/RedeemRecord.vue')
      },       
      {
        path: '/RedeemManagement',
        name: 'RedeemManagement',
        meta:{
          catalog :"产品管理",
          target:"兑换码生成记录记录"
        },
        component: () => import(/* webpackChunkName: "RedeemManagement" */ '../components/product-management/RedeemManagement.vue')
      },      
      {
        path: '/InvoiceManagement',
        name: 'InvoiceManagement',
        meta:{
          catalog :"产品管理",
          target:"发票管理"
        },
        component: () => import(/* webpackChunkName: "InvoiceManagement" */ '../components/product-management/InvoiceManagement.vue')
      },      
      {
        path: '/IdverifyManagement',
        name: 'IdverifyManagement',
        meta:{
          catalog :"产品管理",
          target:"实名认证开关"
        },
        component: () => import(/* webpackChunkName: "IdverifyManagement" */ '../components/product-management/IdverifyManagement.vue')
      },      
      {
        path: '/IdverifyStatistical',
        name: 'IdverifyStatistical',
        meta:{
          catalog :"产品管理",
          target:"实名认证统计"
        },
        component: () => import(/* webpackChunkName: "IdverifyStatistical" */ '../components/product-management/IdverifyStatistical.vue')
      },      
      {
        path: '/EnterpriseStatistical',
        name: 'EnterpriseStatistical',
        meta:{
          catalog :"产品管理",
          target:"企业实名认证统计"
        },
        component: () => import(/* webpackChunkName: "EnterpriseStatistical" */ '../components/product-management/EnterpriseStatistical.vue')
      },      
      {
        path: '/PersonalStatistical',
        name: 'PersonalStatistical',
        meta:{
          catalog :"产品管理",
          target:"个人实名认证详情"
        },
        component: () => import(/* webpackChunkName: "PersonalStatistical" */ '../components/product-management/PersonalStatistical.vue')
      },
      //#endregion 产品管理====================================================================


      //#region 后台人员管理====================================================================
      {
        path: '/AdminList',
        name: 'AdminList',
        meta:{
          catalog :"后台人员管理",
          target:"后台人员列表"
        },
        component: () => import(/* webpackChunkName: "AdminList" */ '../components/admin-management/AdminList.vue')
      },      
      {
        path: '/PermissionManagement',
        name: 'PermissionManagement',
        meta:{
          catalog :"后台人员管理",
          target:"权限管理"
        },
        component: () => import(/* webpackChunkName: "PermissionManagement" */ '../components/admin-management/PermissionManagement.vue')
      },      
      {
        path: '/RoleManagement',
        name: 'RoleManagement',
        meta:{
          catalog :"后台人员管理",
          target:"角色管理"
        },
        component: () => import(/* webpackChunkName: "RoleManagement" */ '../components/admin-management/RoleManagement.vue')
      },
      //#endregion 后台人员管理====================================================================
    
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '*', // 正则*所有
    redirect: '/login'
  }
]


const router = new VueRouter({
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  let cookie = Cookies.get('user_id');
  if(to.path!=="/login" && !cookie){
    Message.warning('请先登陆')
    return next('login')
  }
  next()
})

export default router
