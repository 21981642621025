<template>
  <div>
    <el-form :model="form" ref='form' label-width="180px" label-position="left">
      <el-form-item label="设备类型:" prop="dev">
        <el-radio-group v-model="form.dev" @change="freshData">
          <el-radio label="pc_client">pc端</el-radio>
          <el-radio label="android">android端</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="小于等于该版本时强更:" prop="force_version">
        <el-input v-model="form.force_version"></el-input>
      </el-form-item>      
      <el-form-item label="更新内容:" prop="version_info">
        <el-input type="textarea" v-model="form.version_info" :rows="6" resize="none"></el-input>
      </el-form-item>
      <el-form-item label="更新链接:" prop="version_url">
        <el-input v-model="form.version_url" width="300px"></el-input>
      </el-form-item>
      <el-form-item label="新版本号:" prop="version">
        <el-input v-model="form.version"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="resetForm('form')">清空</el-button>
        <el-button type="primary" @click='submit'>确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { verison , verison_edit } from "@/request/api.js";
export default {
  created(){
    this.form.dev='pc_client'
    this.freshData()
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    freshData(){
      verison({'dev':this.form.dev}).then(
        (res)=>{
          if(res.status==0&&res.detail){
            this.form=res.detail
            this.$message.success('获取成功')
          }else{
            this.resetForm('form')
          }
        }
      )
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submit(){
      let obj = {...this.form}
      delete obj._id
      delete obj.status
      delete obj.updated_at
      verison_edit(obj).then(
        (res)=>{
          if(res.status==0){
            this.$message.success(res.detail)
            this.freshData()
          }else{
            this.$message.error(res.detail)
          }
        }
      )
    }
  }
};
</script>

<style scoped>
>>> .el-input,
>>> .el-textarea {
  width: 500px;
}
</style>