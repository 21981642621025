<template>
  <div class="nav" :class="{'menu50' :isNavCollapse }">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#545c64"
      text-color="#fff"
      collapse-transition
      active-text-color="#ffd04b"
      :collapse="isNavCollapse"
      :router="true"
      :unique-opened="true"
    >
      <el-submenu :index="idx + ''" v-for="(item, idx) in menuData" :key="idx" unique-opened="true">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>{{ item.title }}</span>
        </template>

        <el-menu-item
          v-show="!sitem.grandsonArrayBool"
          :index="sitem.path"
          v-for="(sitem, sidx) in item.subs"
          :key="sidx"
        >{{ sitem.title }}</el-menu-item>

        <el-submenu
          :index="subsItem.title"
          v-for="(subsItem,subsIndex) in item.subs"
          :key="'grandsonArray'+subsIndex"
          v-show="subsItem.grandsonArrayBool"
        >
          <template slot="title">{{subsItem.title}}</template>
          <el-menu-item
            :index="grandsonItem.path"
            v-for="(grandsonItem,grandsonIndex) in subsItem.grandsonArray"
            :key="grandsonIndex"
          >{{grandsonItem.title}}</el-menu-item>
        </el-submenu>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {},
  data() {
    return {
      menuData: [
        {
          title: "服务器与节点",
          subs: [
            { title: "动态vps服务器", path: "/DynamicNode" },
            { title: "静态vps服务器", path: "/StaticNode" },
            // { title: "动态节点信息", path: "41" },
            // { title: "静态节点信息", path: "42" },
            { title: "运营商禁用", path: "/BanOperator" },
            { title: "IP与域名禁用", path: "/DomainNameDisable" }
          ]
        },
        {
          title: "客户端与内嵌网页管理",
          subs: [
            { title: "版本统计", path: "/VersionStatistics" },
            { title: "意见反馈", path: "/Feedback" },
            { title: "举报留言", path: "/ReportMessage" }
          ]
        },
        {
          title: "用户管理",
          subs: [{ title: "全部用户信息", path: "/User" }]
        },
        {
          title: "用户信息查询",
          subs: [{ title: "用户详细信息", path: "/UserInfo" }]
        },
        {
          title: "数据统计与报表",
          subs: [
            { title: "下载统计", path: "/DownloadStatistical" },
            { title: "安装统计", path: "/InstallStatistical" },
            { title: "注册统计", path: "/RegisterStatistical" },
            { title: "用户登录统计", path: "/LoginStatistical" },
            { title: "用户付费统计", path: "/PayStatistical" },
            { title: "用户留存统计", path: "/RetainStatiscal" },
            { title: "用户续费统计", path: "/RenewStatiscal" },
            { title: "用户概况", path: "7" },
            { title: "用户概况统计", path: "8" },
            { title: "注册失败统计", path: "10" },
            { title: "登录次数统计", path: "12" },
            { title: "当前未过期用户数", path: "16" },
            { title: "软件启动统计", path: "18" },
            { title: "渠道统计", path: "20" },
            { title: "关键词分析", path: "23" },
            { title: "推广站分析", path: "23" },
            { title: "当日推广数据分析", path: "24" },
            { title: "后台操作记录", path: "25" }
          ]
        },
        {
          title: "产品管理",
          subs: [
            { title: "套餐管理", path: "/MealManagement" },
            { title: "优惠券管理", path: "/Coupon" },
            { title: "公告管理", path: "/Announcement" },
            { title: "banner图管理", path: "/BannerManagenment" },
            { title: "版本更新", path: "/VersionUpdate" },
            { title: "下载链接管理", path: "/DownloadManagement" },
            {
              title: "实名认证相关",
              grandsonArrayBool: true,
              grandsonArray: [
                { title: "实名认证开关", path: "/IdverifyManagement" },
                { title: "实名认证统计", path: "/IdverifyStatistical" },
                { title: "企业实名认证详情", path: "/EnterpriseStatistical" },
                { title: "个人实名认证详情", path: "/PersonalStatistical" }
              ]
            },
            { title: "订单管理", path: "/OrderManagement" },
            { title: "退款详情", path: "/Refund" },
            {
              title: "兑换码管理",
              grandsonArrayBool: true,
              grandsonArray: [
                { title: "兑换码使用记录", path: "/RedeemRecord" },
                { title: "兑换码生成记录", path: "/RedeemManagement" },
              ]
            },
            { title: "发票管理", path: "/InvoiceManagement" }
          ]
        },
        {
          title: "后台人员管理",
          subs: [
            { title: "后台人员列表", path: "/AdminList" },
            { title: "权限管理", path: "/PermissionManagement" },
            { title: "角色管理", path: "/RoleManagement" }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState({
      isNavCollapse: state => state.navCollapse.isNavCollapse,
      userPathList: state => state.userPath.userPathList
    })
  }
};
</script>
 
<style lang = "less" scoped>
.menu50 {
  width: 64px !important;
  transition: all 0.8s;
}

.nav {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
  width: 250px;
  transition: all 0.8s;
  background-color: rgb(84, 92, 100);
  .el-menu {
    border: 0;
    width: 250px;
  }
  .el-menu--collapse {
    width: 64px;
  }
}

.nav::-webkit-scrollbar {
  width: 0; /*隐藏滚动条 */
}
</style>