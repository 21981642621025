<template>
  <div>
    <el-date-picker
      style="margin-top:20px"
      v-model="dateArr"
      format="yyyy-MM-dd"
      type="daterange"
      value-format="timestamp"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="freshData"
    ></el-date-picker>
    <span>此日期仅供表格导出</span>
    <el-button type="primary" icon="el-icon-search" @click="exportData">导出列表</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="date" label="日期"></el-table-column>
      <el-table-column prop="套餐过期数量" label="套餐过期数量"></el-table-column>
      <el-table-column prop="套餐续费数量" label="套餐续费数量"></el-table-column>
      <el-table-column prop="到期-续费差值" label="到期-续费差值"></el-table-column>
      <el-table-column prop="续费/到期比例" label="续费/到期比例"></el-table-column>
      <el-table-column prop="pc网页到期套餐数" label="pc网页到期套餐数"></el-table-column>
      <el-table-column prop="pc客户端到期套餐数" label="pc客户端到期套餐数"></el-table-column>
      <el-table-column prop="andriod到期套餐数" label="andriod到期套餐数"></el-table-column>
      <el-table-column prop="ios到期套餐数" label="ios到期套餐数"></el-table-column>
      <el-table-column prop="pc网页续费套餐数" label="pc网页续费套餐数"></el-table-column>
      <el-table-column prop="pc客户端续费套餐数" label="pc客户端续费套餐数"></el-table-column>
      <el-table-column prop="andriod续费套餐数" label="andriod续费套餐数"></el-table-column>
      <el-table-column prop="ios续费套餐数" label="ios续费套餐数"></el-table-column>
    </el-table>

    <!--分页器 -->
    <!-- <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[20, 50, 300, 500]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination> -->
  </div>
</template>

<script>
import { renew_past_new} from "@/request/api.js";
export default {
  created(){
    this.freshData()
  },
  data() {
    return {
      dateArr:'',
      tableData: [],
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
    };
  },
  methods: {
    freshData(){
      renew_past_new().then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            this.tableData=res.detail
          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //表格导出
    exportData() {
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 20px;
}
>>> .el-table th {
  user-select: text;
}
</style>
