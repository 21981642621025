<template>
  <div>
    <el-form :model="form" ref='form' label-width="200px" label-position="left">
      <el-form-item label="个人实名认证开关" prop='a'>
        <el-radio-group v-model="form.a">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="支付宝认证开关" prop='b'>
        <el-radio-group v-model="form.b">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="三要素认证开关" prop='c'>
        <el-radio-group v-model="form.c">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="腾讯云认证开关" prop='d'>
        <el-radio-group v-model="form.d">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业实名认证开关" prop='e'>
        <el-radio-group v-model="form.e">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="新用户注册时间" prop='f'>
        <el-date-picker v-model="form.f" type="date" placeholder="选择日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="resetForm('form')">清空</el-button>
        <el-button type='primary'>确认</el-button>

      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
        form:{}
    };
  },
  methods:{
    //清空条件
    resetForm(formName) {
      console.log('1');
      this.$refs[formName].resetFields();
    },
  }
};
</script>

<style>
</style>