<template>
  <div class="userPage">
    <el-form :model="form" ref="form">
      <el-form-item label="用户查询" prop="username">
        <el-input style="width:200px" placeholder="请输入用户名或手机号" v-model="form.username" clearable></el-input>
      </el-form-item>
      <el-form-item label="用户来源" prop="sourceList">
        <el-checkbox-group v-model="form.sourceList">
          <el-checkbox label="android"></el-checkbox>
          <el-checkbox label="ios"></el-checkbox>
          <el-checkbox label="pc_client"></el-checkbox>
          <el-checkbox label="pc"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="用户注册时间" prop="register_time">
        <el-date-picker
          v-model="form.register_time"
          format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <el-button type="primary" @click="freshData" style="margin:0 10px">查询</el-button>
        <el-button type="primary" @click="resetForm('form')">清空搜索条件</el-button>
        <el-button type="primary" @click="exportData('2')">导出表格</el-button>
        <el-button type="primary" @click="addUserDialogFlag=true">新建用户</el-button>
      </el-form-item>
      <!-- <el-form-item label="购买套餐">
        <el-checkbox-group v-model="form.meal">
          <el-checkbox v-for="item in mealList" :key="item.id" :label="item.name"></el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->
    </el-form>

    <!-- 表格数据 -->
    <el-table :data="tableData" stripe style="width:100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="mobile" label="手机号"></el-table-column>
      <el-table-column prop="dev" label="客户端"></el-table-column>
      <el-table-column prop="cert_status" label="是否实名"></el-table-column>
      <el-table-column prop="cert_mobile" label="实名手机"></el-table-column>
      <el-table-column prop="order_no" label="实名存根"></el-table-column>
      <el-table-column prop="enterprise_cert_status" label="是否企业实名"></el-table-column>
      <el-table-column prop="ip" label="IP地址"></el-table-column>
      <el-table-column prop="REMOTE_PORT" label="端口"></el-table-column>
      <el-table-column prop="regis_time" label="注册时间"></el-table-column>
      <el-table-column prop="total" label="充值总计（元）"></el-table-column>
      <el-table-column fixed="right" width="300" prop="type" label="编辑操作">
        <template slot-scope="scope">
          <el-button type="text" @click="readyToEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="addMeal(scope.row)">添加套餐</el-button>
          <el-button type="text" @click="mealDetail(scope.row)">套餐详情</el-button>
          <el-button type="text" @click="deleteUser(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-row>
        <el-col :span="16">
          <el-pagination
            @size-change="changeSize"
            @current-change="changePage"
            :current-page="page"
            :page-sizes="[10,20, 50, 100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <!-- 编辑 -->
    <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" @freshData='freshData'/>
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" :username='addUser'/>
    <Meal v-if="mealDialogFlag" :dialogVisible.sync="mealDialogFlag" :dialogData="mealDialogData" @freshData='freshData'/>

    <!-- 新增用户 -->
    <Add-User v-if="addUserDialogFlag" :dialogVisible.sync="addUserDialogFlag" />
  </div>
</template>

<script>
import { user_all ,user_delete} from "@/request/api.js";
import Edit from "./component/user/edit.vue";
import Add from "./component/user/add.vue";
import AddUser from "./component/user/addUser.vue";
import Meal from "./component/user/meal.vue";
export default {
  components: {
    Edit,
    Add,
    Meal,
    AddUser
  },
  created(){  
    this.freshData()
    
  },
  data() {
    return {
      mealList: [],
      form: {
        sourceList: [],
        // meal: []
      },
      tableData: [
      ],
      editDialogData: {}, //用户编辑
      editDialogFlag: false,
      addDialogFlag: false,
      addUser:"",
      addUserDialogFlag:false,
      mealDialogData: {}, //套餐详情
      mealDialogFlag: false,
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
    };
  },
  methods: {
    //查询
    freshData() {
      console.log(this.form);
      let obj={
        username:this.form.username,
        source:this.form.sourceList.join(','),
        begin_time: this.form.register_time? ((this.form.register_time[0])/1000): "",
        end_time: this.form.register_time? ( 86400+(this.form.register_time[1])/1000 ) : "",
        limit:this.limit,
        page:this.page,
      }

      user_all(obj).then(
        (res)=>{
          if(res.status==0){
            this.total=res.total
            res.detail.forEach((item)=>{
               item.cert_status= item.cert_status==1?"是":"否"
               item.enterprise_cert_status= item.enterprise_cert_status==2?"是":"否"
            })
            this.tableData=res.detail
            this.$message.success('获取成功')
          }else{
            console.log('aaaa');
            
            this.total=0
            this.tableData=[]
          }
        }
      )
      // console.log(obj);
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //清空条件
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //导出表格
    exportData() {
      console.log("导出表格");
    },

    // 编辑
    readyToEdit(row) {
      console.log(row);
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 添加套餐
    addMeal(row) {
      console.log(row);
      this.addDialogFlag = true;
      this.addUser=row.username;
    },
    // 套餐详情
    mealDetail(row) {
      console.log(row);
      this.mealDialogFlag = true;
      this.mealDialogData = row;
    },
    // 删除
    deleteUser(row) {
      console.log(row);
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if(action=='confirm'){
            user_delete({
              id:row.id
            }).then((res)=>{
              if(res.status==0){
                this.$message.success(res.detail)
                this.freshData()
              }
            })
          }
        }
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 30px;
}
.el-row {
  margin-bottom: 20px;
}
.el-checkbox-group {
  display: inline;
}
.el-form-item {
  margin: 0;
}
>>> .el-table th {
  user-select: text;
}
</style>
