<template>
  <div>
    <el-dialog
      title="新增用户"
      :visible.sync="dialogVisible"
      :before-close="cancel">
        <el-form :model="form">
            <el-form-item label="用户名">
                <el-input v-model="form.username" autocomplete="off"></el-input>
            </el-form-item>            
            <el-form-item label="密码">
                <el-input v-model="form.password" autocomplete="off"></el-input>
            </el-form-item>            
            <el-form-item label="手机号">
                <el-input v-model="form.mobile" autocomplete="off" onkeyup='value=value.replace(/\D/g,"")'></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { user_add } from "@/request/api.js";

export default {
    created(){},
    props:{
        dialogVisible:{
            type:Boolean,
            default:false
        },
        username:{
            type:String,
            default:"",
        }
    },
    data(){
        return {
            form:{}
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            if(!this.form.username ||!this.form.password ||!this.form.mobile){
                this.$message.error('参数不能为空')
            }
            user_add(this.form).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.$emit('freshData')
                  this.$emit('update:dialogVisible',false)
                }
              }
            );
        },
    }
}
</script>

<style>

</style>