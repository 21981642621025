<template>
  <div>
    <div class="feedback-type">
      <span>反馈类型 :</span>
      <el-checkbox-group v-model="type">
        <el-checkbox label="节点繁忙" ></el-checkbox>
        <el-checkbox label="自动掉线" ></el-checkbox>
        <el-checkbox label="节点上限" ></el-checkbox>
        <el-checkbox label="节点无法连接" ></el-checkbox>
        <el-checkbox label="充值问题" ></el-checkbox>
        <el-checkbox label="其他问题" ></el-checkbox>
      </el-checkbox-group>
      <el-button type='primary' @click='freshData' style='margin-top:20px'>查询</el-button>
    </div>
     <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column type="index" align="center" label="序号" width="100"> </el-table-column>
      <el-table-column prop="username" align="center" label="用户名" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="mobile" align="center" label="手机号" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="dev" align="center" label="客户端" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="timestamp" align="center" label="时间" show-overflow-tooltip :formatter="formatterADDtime"> </el-table-column>
      <el-table-column prop="type" align="center" label="反馈类型" show-overflow-tooltip > </el-table-column>
      <el-table-column prop="content" align="center" label="反馈内容" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="contanct" align="center" label="联系方式" show-overflow-tooltip> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {feed_back} from "../../request/api.js"
export default {
  data(){
    return {
      type:[],
      FeedbackData:[],
      tableData: []
    }
  },
  created(){
    this.freshData()
  },
  methods:{
    freshData(){
      let obj = {}
      if(this.type.length>0){
        obj.type=[...this.type]
      }
      feed_back(obj).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success('获取成功');
            this.tableData = res.detail;
            this.total = res.total;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        }
      );
    },
    formatterADDtime(row,column,cell){
      return cell=this.detailtime(cell+'')
    }
  }
};
</script>

<style lang = "less" scoped>
.feedback-type {
  margin: 15px 0;
  span{
    margin-right: 10px;
  }
}
.el-radio {
    color: #606266;
    cursor: pointer;
    margin-right: 10px!important;
}
</style>