<template>
  <div>
    <el-dialog title="历史版本编辑" :visible.sync="dialogVisible" :before-close="cancel" append-to-body>
      <el-form :model="form" label-width="200px" label-position="left">
          <el-form-item label='版本号'>
              <el-input v-model='form.version_num' clearable></el-input>
          </el-form-item>          
          <el-form-item label='pc下载地址'>
              <el-input v-model='form.download' clearable></el-input>
          </el-form-item>          
          <el-form-item label='软件大小'>
              <el-input v-model='form.size' clearable></el-input>
          </el-form-item>          
          <el-form-item label='更新时间'>
            <el-date-picker type="datetime" placeholder="选择日期" v-model="form.update_time" value-format="yyyy-MM-dd HH-mm-ss"></el-date-picker>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {channel_version } from "@/request/api.js";

export default {
  created() {
    this.form = {...this.dialogData};
  },
  props: {
    dialogData: {
      type: Object,
      default: {}
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    cancel() {
      this.$emit("update:dialogVisible", false);
    },
    submit() {
      this.form.id=this.form._id
      delete this.form._id
      channel_version(this.form).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success(res.detail);
            this.$emit("freshData");
            this.$emit("update:dialogVisible", false);
          }
        }
      );
    },

  }
};
</script>

<style>
</style>