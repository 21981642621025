<template>
  <div>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="PC">PC</el-menu-item>
        <el-menu-item index="Android">Android</el-menu-item>
        <el-menu-item index="IOS">IOS</el-menu-item>
      </el-menu>
      <div class="line">
        <el-table
          :data="tableData"
          height="743"
          v-loading="tableData.length==0"
          style="width: 100%">
          <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
          <el-table-column prop="version" align="center" width="150" label="用户名" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="count" align="right" label="手机号" show-overflow-tooltip> </el-table-column>
        </el-table>
      </div>
  </div>
</template>

<script>
import {VersionStatisticsData} from '../../request/api'
export default {
   data() {
      return {
        activeIndex: 'PC',
        tableData:[],
        allData:[]
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key);
        this.tableData = this.allData.filter(item=>{
          return item["feedback-type"] == key
        })
        this.tableData.sort((a,b)=>{
          return Number(b.version.split('.').join('')) - Number(a.version.split('.').join(''))
        })
      }
    },
    created(){
      VersionStatisticsData().then(res=>{
        console.log(res);
        this.allData = JSON.parse(JSON.stringify(res.data.articles))
        this.tableData = res.data.articles.filter(item=>{
          return item["feedback-type"] == this.activeIndex
        })
        this.tableData.sort((a,b)=>{
          return Number(b.version.split('.').join('')) - Number(a.version.split('.').join(''))
        })
      })
    }
}
</script>

<style lang = "less" scoped>

.el-menu.el-menu--horizontal {
    margin-bottom: 15px;
    border-bottom: solid 1px#e6e6e6;
}
</style>