<template>
  <div>
    <el-input style="width:300px" placeholder="请输入角色名称" v-model="username" clearable>
        <el-button slot="append" icon="el-icon-search"  @click="freshData"></el-button>
    </el-input>

    <!-- 表格数据 -->
    <el-table :data="tableData" stripe style="width:100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="username" label="角色名称"></el-table-column>
      <el-table-column prop="cert_mobile" label="创建时间"></el-table-column>
      <el-table-column fixed="right" width="300" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="readyToEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="deleteUser(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-row>
        <el-col :span="16">
          <el-pagination
            @size-change="changeSize"
            @current-change="changePage"
            :current-page="page"
            :page-sizes="[10,20, 50, 100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <!-- 编辑 -->
    <!-- <Edit v-if="editDialogFlag" :dialogVisible.sync="editDialogFlag" :dialogData="editDialogData" />
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" />
    <Meal v-if="mealDialogFlag" :dialogVisible.sync="mealDialogFlag" :dialogData="mealDialogData" /> -->
  </div>
</template>

<script>
import {web_user_list } from "@/request/api.js";
// import Edit from "./component/user/edit.vue";
// import Add from "./component/user/add.vue";
// import Meal from "./component/user/meal.vue";
export default {
  components: {
    // Edit,
    // Add,
    // Meal
  },
  created(){  
    this.freshData()
  },
  data() {
    return {
      tableData: [],
      editDialogData: {}, //用户编辑
      editDialogFlag: false,
      addDialogFlag: false,
      mealDialogData: {}, //套餐详情
      mealDialogFlag: false,
      page:1,//页码
      limit:10,//页容量
      total:0,//总条数
      username:''
    };
  },
  methods: {
    //查询
    freshData() {
      web_user_list().then(
        (res)=>{
          if(res.status==0){
            this.total=res.total
            this.tableData=res.detail
            this.$message.success('获取成功')
          }else{
            this.total=0
            this.tableData=[]
          }
        }
      )
      // console.log(obj);
    },
    changeSize(val){
      // this.limit=val;
      this.freshData()
    },
    changePage(val){
      // this.page=val;
      this.freshData()
    },
    // 编辑
    readyToEdit(row) {
      console.log(row);
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 删除
    deleteUser(row) {
      console.log(row);
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: () => {
          user_delete({
            id:row.id
          }).then((res)=>{
            if(res.status==0){
              this.$message.success(res.detail)
              this.freshData()
            }
          })
        }
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-helper {
  margin-bottom: 30px;
}
.el-row {
  margin-bottom: 20px;
}
.el-checkbox-group {
  display: inline;
}
.el-form-item {
  margin: 0;
}
>>> .el-table th {
  user-select: text;
}
</style>
