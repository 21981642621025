<template>
  <div>
    <el-dialog title="渠道号" :visible.sync="dialogVisible" :before-close="cancel">
      <el-radio-group v-model="dev" @change="radioChange" style="margin:20px 0">
        <el-radio-button label="pc">pc下载地址</el-radio-button>
        <el-radio-button label="android">android下载地址</el-radio-button>
        <el-radio-button label="ios">ios下载地址</el-radio-button>
        <el-radio-button label="qy_pc">企业版下载地址</el-radio-button>
      </el-radio-group>
      <el-button
        @click="addVersion"
        type="primary"
        style="display:block;margin:20px 0"
      >新增版本</el-button>
      <el-table :data="historyData" border style="width: 100%">
        <el-table-column prop="version_num" label="版本号"></el-table-column>
        <el-table-column prop="size" label="软件大小"></el-table-column>
        <el-table-column prop="update_time" label="更新时间"></el-table-column>
        <el-table-column prop="download" label="下载地址"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="deleteID(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 编辑 -->
      <Edit
        v-if="editDialogFlag"
        :dialogVisible.sync="editDialogFlag"
        :dialogData="editDialogData"
        @freshData='freshData'
      />
      <!-- 新增 -->
      <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" :dialogData="addDialogData" @freshData='freshData'/>
    </el-dialog>
  </div>
</template>

<script>
import Edit from "./historyEdit.vue";
import Add from "./addVersion.vue";
import { channel_version_link, channel_version_del } from "@/request/api.js";

export default {
  components: {
    Edit,
    Add
  },
  created() {
    this.channel=this.dialogData.channel
    this.freshData()
  },
  props: {
    dialogData: {
      type: Object,
      default: {}
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      channel:"",
      dev: "pc",
      historyData: [],
      editDialogData: {}, 
      editDialogFlag: false,
      addDialogFlag: false,
      addDialogData:{}
    };
  },
  methods: {
    freshData(){
      channel_version_link({'channel':this.channel,"dev":this.dev}).then(
        (res) => {
          if (res.status == 0) {
            console.log(res.detail);
            this.historyData=res.detail
          }
        }
      );
    },
    cancel() {
      this.$emit("update:dialogVisible", false);
    },
    radioChange(){
      this.freshData()
    },
    //新增版本
    addVersion(){
      this.addDialogData.dev=this.dev
      this.addDialogData.channel=this.channel
      this.addDialogFlag=true
    },
    //编辑
    edit(row) {
      this.editDialogFlag = true;
      this.editDialogData = row;
    },
    // 删除
    deleteID(row) {
      // console.log(id);
      this.$alert("你正在进行删除操作", "提示", {
        confirmButtonText: "确定",
        callback: action => {
          if(action=='confirm'){
            let obj={
              channel:row.channel,
              id:row._id
            }
            channel_version_del(row).then(
              (res) => {
                if (res.status == 0) {
                  this.$message.success(res.detail);
                  this.freshData()
                }
              }
            );
          }
        }
      });
    }
  }
};
</script>

<style lang = "less" scoped>
.el-input {
  width: 400px;
}
</style>