<template>
  <div>
    <el-dialog
      title="新增套餐"
      :visible.sync="dialogVisible"
      :before-close="cancel">
        <el-form :model="form" label-width="200px" label-position="left">
            <el-form-item label="套餐类型">
                <el-radio-group v-model="form.type">
                    <el-radio label="ordinary">普通套餐</el-radio>
                    <el-radio label="advanced">高级套餐</el-radio>
                    <el-radio label="addition">加油包</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="套餐名称">
                <el-input v-model='form.name'></el-input>
            </el-form-item>            
            <el-form-item label="套餐流量">
                <el-input v-model='form.flow'></el-input>
            </el-form-item>            
            <el-form-item label="套餐时长（天）" v-if='form.type=="ordinary"'>
                <el-input v-model='form.long'></el-input>
            </el-form-item>            
            <el-form-item label="官网套餐价格（元）">
                <el-input v-model='form.web_price'></el-input>
            </el-form-item>            
            <el-form-item label="PC端套餐价格（元）">
                <el-input v-model='form.pc_price'></el-input>
            </el-form-item>            
            <el-form-item label="android端套餐价格（元）">
                <el-input v-model='form.android_price'></el-input>
            </el-form-item>            
            <el-form-item label="原价（元）">
                <el-input v-model='form.pc_original'></el-input>
            </el-form-item>            
            <el-form-item label="是否显示标签">
                <el-radio-group v-model="form.is_tag">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
            </el-form-item>            
            <el-form-item label="标签名字">
                <el-input v-model='form.tag'></el-input>
            </el-form-item>            
            <el-form-item label="在线数单价（元）">
                <el-input v-model='form.online_price'></el-input>
            </el-form-item>            
            <el-form-item label="备注">
                <el-input v-model='form.note'></el-input>
            </el-form-item>            
            <el-form-item label="状态">
                <el-radio-group v-model="form.is_published">
                    <el-radio :label="1">有效</el-radio>
                    <el-radio :label="0">失效</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { meal_add } from "@/request/api.js";
export default {
    created(){
    },
    props:{
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{
                type:"ordinary"
            },
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit(){
            if(this.form.type=="ordinary" && !this.form.long){
                this.$message.error("套餐时长不能为空")
                return
            }
            if(this.form.type!='addition'){
                if(!this.form.online_price){
                    this.$message.error("普通套餐和高级套餐，在线数价格不能为空")
                    return
                }
            }

            meal_add(this.form).then(
                (res)=>{
                    if(res.status==0){
                        this.$message.success(res.detail)
                        this.$emit('freshData')
                        this.$emit('update:dialogVisible',false)
                    }else{
                        this.$messag.error(res.detail)
                    }
                }
            )
        }
    }
}
</script>

<style lang = "less" scoped>
.el-input {
    width: 300px;
}
</style>