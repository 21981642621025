<template>
  <div>
    <el-form :model="form" ref="form" label-width="150px" label-position="left">
      <el-form-item label="兑换码类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择" clearable>
          <el-option label="一码一用（标准码）" :value="1"></el-option>
          <el-option label="一码多用（通用码）" :value="0"></el-option>
        </el-select>
        <el-button type="primary" style="margin:0 20px" @click="addDialogFlag=true">生成兑换码</el-button>
      </el-form-item>
      <el-form-item label="兑换码时间" prop="time">
        <el-date-picker
          v-model="form.time"
          format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="兑换码查询" prop="code">
        <el-input v-model="form.code" clearable style="width:300px"></el-input>
      </el-form-item>        
      <el-form-item label="套餐时长（天）" prop="available_day">
        <el-input v-model="form.available_day" clearable style="width:300px"></el-input>
      </el-form-item>      
        <el-button type="primary" @click="freshData" style="margin: 0 10px">查询</el-button>
        <el-button type="primary" @click="resetForm('form')">清空搜索条件</el-button>
        <el-button type="primary" @click="exportData()">导出表格</el-button>

    </el-form>

    <!-- 表格数据 -->
    <el-table :data="tableData" stripe style="width:100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="code" label="兑换码"></el-table-column>
      <el-table-column prop="type" label="兑换类型" ></el-table-column>
      <el-table-column prop="flow_total" label="可用流量"></el-table-column>
      <el-table-column prop="available_day" label="套餐时长"></el-table-column>
      <el-table-column prop='exchangetime'  label="可兑换时间">

      </el-table-column>
      <el-table-column prop="timestamp" label="生成时间"></el-table-column>
      <el-table-column prop="redeem_status" label="兑换状态"></el-table-column>
      <el-table-column prop="note" label="备注"></el-table-column>
      <el-table-column label="操作(只可取消使用)">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" active-color="#fb8f90" inactive-color="#13ce66" active-value="失效" :disabled="scope.row.redeem_status!='可使用'" @change='cancelUse(scope.row.code)'></el-switch>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页器 -->
    <el-pagination
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="page"
      :page-sizes="[10,20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <!-- 新增 -->
    <Add v-if="addDialogFlag" :dialogVisible.sync="addDialogFlag" @freshData='freshData'/>
  </div>
</template>

<script>
import Add from "./components/RedeemRecord/add.vue";
import { redeem_list,code_export,redeem_code_edit } from "@/request/api.js";
export default {
  components: {
    Add
  },
  created(){
    this.freshData()
  },
  data() {
    return {
      form: {},
      tableData:[],
      addDialogFlag: false,
      total:0,
      limit:10,
      page:1,

    };
  },
  methods: {
    //查询
    freshData() {
      let obj={...this.form}
      if(obj.time){
        obj.time_begin=this.formmaterDate(obj['time'][0])
        obj.time_end=this.formmaterDate(obj['time'][1])
      }
      if(obj.meal_type==''){
        delete obj['meal_type']
      }
      obj.limit=this.limit
      obj.page=this.page
      delete obj['time']
      redeem_list(obj).then(
        (res)=>{
          if(res.status==0 && res.detail){
            res.detail.forEach((item)=>{
              item.timestamp=this.detailtime(item.timestamp*1000)
              item.type=item.type==1?'一码一用（标准码）':'一码多用（通用码）'
              item.exchangetime=`${this.formmaterDate(item.exchange_start_time+'')}-${this.formmaterDate(item.exchange_end_time+'')}`
              item.status=item.redeem_status
            })
            this.tableData=res.detail
            this.total=res.total
            this.$message.success('获取成功')
          }else{
            this.tableData=[]
            this.total=0
          }
        }
      )
    },
    changeSize(val){
      this.limit=val;
      this.freshData()
    },
    changePage(val){
      this.page=val;
      this.freshData()
    },
    //清空条件
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //导出表格
    exportData() {
      let obj={...this.form}
      if(obj.time){
        obj.code_time_begin=this.formmaterDate(obj['time'][0])
        obj.code_time_end=this.formmaterDate(obj['time'][1])
      }
      if(obj.type==''){
        delete obj['type']
      }
      delete obj['time']
      code_export(obj)
      console.log("导出表格");
    },

    //兑换码取消
    cancelUse(code){
      redeem_code_edit({code,"redeem_status":"失效"}).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success(res.detail);
            this.freshData()
          }
        }
      );
    }
  }
};
</script>

<style>
</style>