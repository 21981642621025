<template>
  <div>
    <el-dialog title="编辑优惠券" :visible.sync="dialogVisible" :before-close="cancel">
      <el-form :model="form" label-width="120px" label-position="left">
        <el-form-item label="优惠券名称">
          <el-input v-model="form.discount_name"></el-input>
        </el-form-item>
        <el-form-item label="可用套餐">
          <el-radio-group v-model="form.meal_type">
            <el-radio label="全部套餐"></el-radio>
            <el-radio label="普通套餐"></el-radio>
            <el-radio label="高级套餐"></el-radio>
            <el-radio label="加油包"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="可用用户">
          <el-radio-group v-model="form.user_type">
            <el-radio label="全部"></el-radio>
            <el-radio label="新用户"></el-radio>
            <el-radio label="老用户"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="优惠券金额">
          满
          <el-input v-model="form.full_reduction" style="width: 80px; margin:0 15px"></el-input>
          减
          <el-input v-model="form.minus_price" style="width: 80px"></el-input>
        </el-form-item>
        <el-form-item label="可用日期" prop='time'>
            <el-date-picker
            v-model="form.time"
            format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            ></el-date-picker>
        </el-form-item>
        <el-form-item label="可用时长">
          <el-input v-model="form.valid_day" style="width: 80px; margin-left: 5px"></el-input>天
        </el-form-item>
        <el-form-item label="时长类型">
          <el-radio-group v-model="form.date_type">
            <!-- 1代表可用天数    2代表可用日期 -->
            <el-radio :label="2">可用日期</el-radio>
            <el-radio :label="1">可用时长</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="使用状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">关</el-radio>
            <el-radio :label="2">开</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { discount_coupon } from "@/request/api.js";
export default {
    created(){
        this.form={...this.dialogData}
        if(this.form.start_time){
          this.form.time.push(new Date(this.form.start_time*1000))
          this.form.time.push(new Date(this.form.end_time*1000))
        }
        console.log(this.form);
    },
    props:{
        dialogData: {
            type: Object,
            default: {},
        },
        dialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{
              time:[]
            },
            defaultTime:""
        }
    },
    methods:{
        cancel(){
            this.$emit('update:dialogVisible',false)
        },
        submit() {
          let obj ={...this.form}
          if(obj.time){
            obj.start_time=obj.time[0]/1000
            obj.end_time=(Number(obj.time[1])+86400000)/1000
          }
          delete obj.time
          discount_coupon(obj).then(
            (res)=>{
              if(res.status==0){
                this.$message.success(res.detail)
                this.$emit("freshData");
                this.$emit("update:dialogVisible", false);
              }else{
                this.$message.error(res.detail)
              }
            }
          )
        }
    }
}
</script>

<style lang = "less" scoped>
.el-input {
    width: 400px;
}
</style>