<template>
  <div>
    <el-dialog title="生成兑换码" :visible.sync="dialogVisible" :before-close="cancel">
      <el-form :model="form" label-width="200px" label-position="left">
        <el-form-item label="可用套餐">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">一码一用（标准码）</el-radio>
            <el-radio :label="0">一码多用（通用码）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="可用流量（GB/MB）">
          <el-input v-model="form.flow_total" style="width: 100px"></el-input>
            <el-select v-model="form.flow_type" placeholder="请选择" clearable style='width:100px;margin-left:20px'>
              <el-option label="GB" value="GB"></el-option>
              <el-option label="MB" value="MB"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="套餐时长（天）">
          <el-radio-group v-model="form.meal_type">
            <!-- 1代表可用天数    2代表可用日期 -->
            <el-radio :label="2">不限时</el-radio>
            <el-radio :label="1">限时</el-radio>
          </el-radio-group>
          <el-input v-model='form.available_day' clearable style='width:80px;margin-left:20px'></el-input>天
        </el-form-item>
        <el-form-item label="可兑换时间" prop='time'>
            <el-date-picker
            v-model="form.time"
            format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            ></el-date-picker>
        </el-form-item>
        <el-form-item label="生成个数">
          <el-input v-model="form.num" style="width: 150px; margin-left: 5px"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.note" style="width: 150px; margin-left: 5px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { redeem_code_add } from "@/request/api.js";
export default {
  created() {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    cancel() {
      this.$emit("update:dialogVisible", false);
    },
    submit() {
      let obj={...this.form}
      if(!obj.type&&obj.type!=0){
        this.$message.error('兑换码类型不能为空')
        return
      }      
      if(!obj.flow_total){
        this.$message.error('可用流量不能为空')
        return
      }
      if(this.form.flow_type){
        if(this.form.flow_type=='MB'){
          obj.flow_total=this.form.flow_total/1024
        }
      }else{
        this.$message.error('套餐单位不能为空')
        return
      }

      if(obj.meal_type){
        if(obj.meal_type==2){
          delete obj['available_day']
        }
        if(obj.meal_type==1 && !obj.available_day){
          this.$message.error('天数不能为空')
          return
        }
      }else{
        this.$message.error('套餐时长类型不能为空')
        return
      }

      if(obj.time){
        obj.exchange_start_time=this.formmaterDate(obj.time[0])
        obj.exchange_end_time=this.formmaterDate(obj.time[1])
      }else{
        this.$message.error('兑换时间不能为空')
        return
      }

      if(!obj.num){
        this.$message.error('生成数量不能为空')
        return
      }
      delete obj.time
      delete obj.meal_type
      delete obj.flow_type
      
      redeem_code_add(obj).then(
        (res)=>{
          if(res.status==0){
            this.$message.success(res.detail)
            this.$emit("freshData");
            this.$emit("update:dialogVisible", false);
          }
        }
      )

    }
  }
};
</script>

<style lang = "less" scoped>
.el-input {
  width: 400px;
}
</style>